import React, { useState, useEffect } from "react";
import { Grid, MenuItem, TextField, InputAdornment } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";

import Map from "./Map";
import actionsDispositions from "../../../../redux/actions/dispositions";

export default function Locations({ regionData, provinceData, typeOfTreatments }) {
  const dispatch = useDispatch();

  const [locations, setLocations] = useState([]);
  const [typeSearch, setTypeSearch] = useState("REGION");

  const dispositions = useSelector((state) => state.dispositions);

  useEffect(() => {
    if (dispositions.region !== "EMPTY" && dispositions.province === "EMPTY" && dispositions.typeOfTreatment === "EMPTY") {
      dispatch(actionsDispositions.fetchDispositionsLocationsByRegions(dispositions.region));
    }
    if (dispositions.region === "EMPTY" && dispositions.province !== "EMPTY" && dispositions.typeOfTreatment === "EMPTY") {
      dispatch(actionsDispositions.fetchDispositionsLocationByProvince(dispositions.province));
    }
    if (dispositions.region === "EMPTY" && dispositions.province === "EMPTY" && dispositions.typeOfTreatment !== "EMPTY") {
      dispatch(actionsDispositions.fetchDispositionsLocationByTypeOfTreatment(dispositions.typeOfTreatment));
    }
    if (dispositions.region === "EMPTY" && dispositions.province === "EMPTY" && dispositions.typeOfTreatment === "EMPTY") {
      setLocations(dispositions.dispositions)
    }
  }, [dispositions.province, dispositions.region, dispositions.typeOfTreatment, dispositions.dispositions]);

  useEffect(() => {
    regionData.sort((x, y) => {
      if (x.regName > y.regName) {
        return 1;
      }
      if (x.regName < y.regName) {
        return -1;
      }
      return 0;
    })
    provinceData.sort((x, y) => {
      if (x.provCode > y.provCode) {
        return 1;
      }
      if (x.provCode < y.provCode) {
        return -1;
      }
      return 0;
    })
  }, [])

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };

  const handleChangeRegion = (e) => {
    if (e === "" || e === 0 || e === null || e === undefined) {
      //Control de excepciones
    } else {
      dispatch(actionsDispositions.setRegion(e.regCode));
    }
  }
  const handleChangeProvince = (e) => {
    if (e === "" || e === 0 || e === null || e === undefined) {
      //Control de excepciones
    } else {
      dispatch(actionsDispositions.setProvince(e.provCode));
    }
  }
  const handleChangeTypeOfTreatment = (e) => {
    if (e === "" || e === 0 || e === null || e === undefined) {
      //Control de excepciones
    } else {
      dispatch(actionsDispositions.setTypeOfTreatment(e.code))
    }
  }

  const typeOfSearchLabel = () => {
    if (typeSearch === "REGION") {
      return "Selecciona una región"
    } else if (typeSearch === "PROVINCE") {
      return "Selecciona una provincia"
    } else {
      return "Selecciona un tipo de tratamiento"
    }
  }

  function Item(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label={typeOfSearchLabel()}
        />
      </div>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="outlined-required"
            label="Tipo de búsqueda"
            select
            value={typeSearch}
            onChange={(e) => setTypeSearch(e.target.value)}
            {...style}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="REGION">Región</MenuItem>
            <MenuItem value="PROVINCE" disabled>Provincia</MenuItem>
            <MenuItem value="TYPE_OF_TREATMENT" disabled>Tipo de tratamiento</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          {typeSearch === "REGION" ? (
            <Autocomplete
              id="cmbRegion"
              options={regionData}
              freeSolo
              getOptionLabel={(option) => option.regName}
              getOptionSelected={(option, value) => option.regName === value.regName}
              onSelect={(event, newValue) => {
                handleChangeRegion(newValue);
              }}
              onChange={(event, newValue) => {
                handleChangeRegion(newValue);
              }}
              renderInput={(params) => Item(params)}
            />
          ) : typeSearch === "PROVINCE" ? (<>
            <Autocomplete
              id="cmbProvince"
              options={provinceData}
              freeSolo
              getOptionLabel={(option) => option.provCode}
              getOptionSelected={(option, value) => option.provCode === value.provCode}
              onSelect={(event, newValue) => {
                handleChangeProvince(newValue);
              }}
              onChange={(event, newValue) => {
                handleChangeProvince(newValue);
              }}
              renderInput={(params) => Item(params)}
            />
          </>) :
            (<>
              <Autocomplete
                id="cmbTypeOfTreatment"
                options={typeOfTreatments}
                freeSolo
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.code === value.code}
                onSelect={(event, newValue) => {
                  handleChangeTypeOfTreatment(newValue);
                }}
                onChange={(event, newValue) => {
                  handleChangeTypeOfTreatment(newValue);
                }}
                renderInput={(params) => Item(params)}
              />
            </>)
          }
        </Grid>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {dispositions.region !== "EMPTY" && dispositions.province === "EMPTY" && dispositions.typeOfTreatment === "EMPTY" &&
            <Map data={dispositions.locations} />
          }
          {dispositions.region === "EMPTY" && dispositions.province !== "EMPTY" && dispositions.typeOfTreatment === "EMPTY" &&
            <Map data={dispositions.locations} />
          }
          {dispositions.region === "EMPTY" && dispositions.province === "EMPTY" && dispositions.typeOfTreatment !== "EMPTY" &&
            <Map data={dispositions.locations} />
          }
          {dispositions.region === "EMPTY" && dispositions.province === "EMPTY" && dispositions.typeOfTreatment === "EMPTY" &&
            <Map data={locations} />
          }
        </Grid>
      </Grid>
    </>
  );
}
