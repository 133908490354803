import React, { useState, useEffect } from "react";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Slide from "@material-ui/core/Slide";
import { useSelector, useDispatch } from "react-redux";
import notifiesActions from "../../redux/actions/notifies";
import PropTypes from "prop-types";

function AlertComponent({ severity, text }) {
  return (
    <Alert elevation={6} severity={severity} variant="filled">
      <Typography variant="body1">{text}</Typography>
    </Alert>
  );
}

export default function Notifications() {
  const dispatch = useDispatch();
  const notifies = useSelector((state) => state.notifies);
  const [notification, setNotification] = useState({
    open: false,
  });

  useEffect(() => {
    if (notifies.length) {
      setNotification({
        open: true,
        ...notifies[0],
      });

      setTimeout(() => {
        setNotification({
          open: false,
          ...notification,
        });
        dispatch(notifiesActions.dequeueNotify());
      }, notifies[0].duration);
    }
  }, [notifies]);

  return (
    <Slide direction="up" in={notification.open}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={true}
        onClose={null}
      >
        <AlertComponent {...notification} />
      </Snackbar>
    </Slide>
  );
}

AlertComponent.propTypes = {
  severity: PropTypes.string,
  text: PropTypes.string,
};
