import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import usersActions from "../../../redux/actions/users";

function ResponsiveDialog() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [error, setError] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    rptPassword: "",
    phone: "",
  });

  useEffect(() => {
    if (users.dataModal) {
      setFormData({
        ...users.dataModal,
      });
    }
  }, [users.dataModal]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const updateData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSuccess = () => {
    if (
      formData.email === "" ||
      (formData.password.length < 7 && formData.password.length > 0) ||
      formData.name === ""
    ) {
      setError(true);
    } else {
      var payload = {
        name: formData.name,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
      };
      if (formData.password) {
        payload = {
          ...payload,
          password: formData.password,
        };
      }
      console.log(payload);
    }
  };

  const handleClose = () => {
    dispatch(usersActions.setDataModal(null));
    dispatch(usersActions.setEditModal(false));
  };

  return (
    <Dialog
      open={users.editModal}
      onClose={() => handleClose()}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Typography variant="h5">Editar usuario</Typography>
        <hr></hr>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              value={formData.name}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Nombre"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && !formData.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="lastName"
              value={formData.lastName}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Apellido"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && !formData.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={formData.email}
              margin="dense"
              label="E-mail"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone"
              value={formData.phone}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Teléfono"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && !formData.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              value={formData.password}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Password"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && formData.password.length < 7}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button color="secondary" onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleSuccess()}>
            Actualizar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}

export default ResponsiveDialog;
