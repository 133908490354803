import axios from "axios";
const login = ({ email, password }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "AUTH_SET_LOGIN_STATUS",
        payload: {
          error: false,
          step: "LOADING",
          message: "",
        },
      });
      let response = await axios.post("/auth/login", { email, password });
      if (response.data.type !== "ADMIN") {
        dispatch({ type: "AUTH_LOGIN_FAIL" });
        dispatch({
          type: "AUTH_SET_LOGIN_STATUS",
          payload: {
            error: true,
            step: "ERROR",
            message: "USER_NOT_ADMIN_TYPE",
          },
        });
      } else {
        window.localStorage.setItem("token", response.data.token);
        axios.defaults.headers.common.authorization = `${response.data.token}`;
        dispatch({ type: "AUTH_LOGIN_SUCCESS" });
        dispatch({
          type: "AUTH_SET_LOGIN_STATUS",
          payload: {
            error: false,
            step: "WELCOME",
            message: "SUCCESS",
          },
        });
      }
    } catch (e) {
      alert("Correo y/o Contraseña incorrecta");
      dispatch({ type: "AUTH_LOGIN_FAIL" });
      dispatch({
        type: "AUTH_SET_LOGIN_STATUS",
        payload: {
          error: true,
          step: "ERROR",
          message: "",
        },
      });
    }
  };
};

const verifyToken = () => {
  return async (dispatch) => {
    try {
      await axios.post("/auth/token_check");
      dispatch({ type: "AUTH_LOGIN_SUCCESS" });
    } catch (e) {
      dispatch(logout());
    }
  };
};

const logout = () => {
  return async (dispatch) => {
    axios.defaults.headers.common.authorization = null;
    window.localStorage.removeItem("token");
    dispatch({ type: "AUTH_LOGOUT" });
    return true;
  };
};

export default {
  login,
  logout,
  verifyToken,
};
