const defaultState = {
  dispositions: [],
  locations: [],
  region: "EMPTY",
  province: "EMPTY",
  typeOfTreatment: "EMPTY",
  dataModal: null,
  editModal: false,
  deleteModal: false,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "DISPOSITIONS_FETCH": {
      return { ...state, dispositions: payload, locations: [], region: "EMPTY", province: "EMPTY", typeOfTreatment: "EMPTY" };
    }
    case "DISPOSITIONS_LOCATION_BY_REGION": {
      return { ...state, region: payload, province: "EMPTY", typeOfTreatment: "EMPTY" };
    }
    case "DISPOSITIONS_LOCATION_BY_PROVINCE": {
      return { ...state, region: "EMPTY", province: payload, typeOfTreatment: "EMPTY" };
    }
    case "DISPOSITIONS_LOCATION_BY_TYPE_OF_TREATMENT": {
      return { ...state, region: "EMPTY", province: "EMPTY", typeOfTreatment: payload };
    }
    case "DISPOSITIONS_FETCH_LOCATION_BY_REGION_SUCCESS": {
      return { ...state, locations: payload };
    }
    case "DISPOSITIONS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "DISPOSITIONS_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "DISPOSITIONS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "RESET": {
      return { ...state, dispositions: [], locations: [], region: "EMPTY", province: "EMPTY", typeOfTreatment: "EMPTY" };
    }
    default:
      return state;
  }
}

export default reducer;