import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  MenuItem
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";

import TableVehicles from "./TableVehicles";
import functions from "../../../../resources/functions";

const initState = {
  brand: "",
  model: "",
  licensePlate: "",
  type: "",
  maxVolume: 0,
  resolutions: [],
  description: "",
  typeUnit: "",
}

const resolutions = [
  { name: 'Residuos peligrosos', code: 'RP' },
  { name: 'Residuos No peligrosos', code: 'RNP' },
];

export default function Vehicles({ vehiclesData, setVehiclesData, error, setError, resolutionsSelected, setResolutionsSelected }) {
  const [addVehicle, setAddVehicle] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [status, setStatus] = useState(0);  // 0 incorrect form, 1 correct form
  const [formDataVehicles, setformDataVehicles] = useState(initState);

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };
   const style2 = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "number",
    InputLabelProps: {
      shrink: true,
    },
  };

  useState(() => {
    setVehicles(vehiclesData);
  }, [vehiclesData]);

  useEffect(() => {
    if (
      // formDataVehicles.brand === "" ||
      // !formDataVehicles.model ||
      // formDataVehicles.resolution === "" ||
      formDataVehicles.licensePlate === ""
    ) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  }, [formDataVehicles]);

  const resetFormData = () => {
    setformDataVehicles(initState);
    setAddVehicle(false);
    setResolutionsSelected([]);
  }

  const updateData = (data, name) => {
    setformDataVehicles({ ...formDataVehicles, [name]: data });
  };

  const addVehicleToArray = () => {
    let aux = [];
    vehicles.forEach((vehicleElement) => {
      aux.push(vehicleElement);
    });
    aux.push(formDataVehicles);
    setVehicles(aux);
    setVehiclesData(aux);
    resetFormData();
  };

  const deleteVehicle = (key) => {
    let aux = [];
    vehicles.forEach((vehicleElement, i) => {
      if (parseInt(i, 0) !== parseInt(key, 0)) {
        aux.push(vehicleElement);
      }
    });
    setVehicles(aux);
    setVehiclesData(aux);
  };

  const formatLicensePlate = (licensePlate) => {
    return functions.formatLicensePlate(licensePlate);
  };

  const handleResolutions = (k) => {
    setResolutionsSelected(k);
    setformDataVehicles({
      ...formDataVehicles, resolutions: k.map(element => {
        return element.code
      })
    })
  };

  function Item(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Resolución"
          error={error && !formDataVehicles.resolutions}
        />
      </div>
    );
  }

  return (
    <div>
      <hr></hr>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>Datos vehículos</h5>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              color={addVehicle ? "primary" : "secondary"}
              onClick={addVehicle ? () => resetFormData() : () => setAddVehicle(!addVehicle)}
              variant={addVehicle ? "outlined" : "text"}
            >
              {addVehicle ? "- Descartar vehículo" : "+ Agregar vehículo"}
            </Button>
          </Grid>
          <Grid item xs={6}>
            {addVehicle && (
              <Box textAlign='right'>
                <Button
                  color="secondary"
                  variant={status ? "outlined" : "contained"}
                  style={{ color: status ? "#00C851" : "white" }}
                  onClick={status ? () => addVehicleToArray() : () => setError(true)}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Agregar al listado
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>

        {addVehicle && (
          <React.Fragment>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Marca"
                value={formDataVehicles.brand}
                onChange={(event) => updateData(event.target.value, "brand")}
              // error={error && !formDataVehicles.brand}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Modelo"
                value={formDataVehicles.model}
                onChange={(event) => updateData(event.target.value, "model")}
              // error={error && !formDataVehicles.model}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Placa patente"
                value={formatLicensePlate(formDataVehicles.licensePlate)}
                onChange={(event) => updateData(event.target.value, "licensePlate")}
                error={error && !formDataVehicles.licensePlate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...style}
                label="Tipo"
                value={formDataVehicles.type}
                onChange={(event) => updateData(event.target.value, "type")}
              // error={error && !formDataVehicles.type}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
				
                {...style2}
                label="Máximo Volumen"
                value={formDataVehicles.maxVolume}
                onChange={(event) => updateData(event.target.value, "maxVolume")}
              // error={error && !formDataVehicles.maxVolume}
              />
            </Grid>
            <Grid item xs={2}>
              {/* <TextField
                {...style}
                name="typeUnit"
                label="Unidades"
                value={formDataVehicles.typeUnit}
                onChange={(event) => updateData(event.target.value, "typeUnit")}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              // error={error && !formDataVehicles.maxVolume}
              >
                <MenuItem value="litros">Litros</MenuItem>
                <MenuItem value="m3">M3</MenuItem>
                <MenuItem value="toneladas">Toneladas</MenuItem>
                </TextField>
            </Grid> */}

            <TextField
            {...style}
              name="type"
              value={formDataVehicles.typeUnit}
              defaultValue="litros"
              onChange={(event) => updateData(event.target.value, "typeUnit")}
              select
              margin="dense"
              label="Unidades"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="litros">Litros</MenuItem>
                <MenuItem value="m3">M3</MenuItem>
                <MenuItem value="toneladas">Toneladas</MenuItem>
            </TextField>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="resolutions-select"
                options={resolutions}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                value={resolutionsSelected}
                renderInput={(params) => Item(params)}
                onChange={(_, k) => handleResolutions(k)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...style}
                label="Descripción"
                multiline
                rows={4}
                value={formDataVehicles.description}
                onChange={(event) => updateData(event.target.value, "description")}
              // error={error && !formDataVehicles.description}
              />
            </Grid>
          </React.Fragment>
        )
        }
        <Grid item xs={12}>
          <TableVehicles
            vehicles={vehicles}
            deleteVehicle={deleteVehicle}
          />
        </Grid>
      </Grid>
    </div>
  );
}


