import React, { useEffect } from "react";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReportsList from "./ReportList";
import ModalCreate from "./ModalCreate";
import ModalDelete from "./ModalDelete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useSelector, useDispatch } from "react-redux";
import reportsActions from "../../../redux/actions/reports";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DashboardPage() {
  const classes = useStyles();

  const reports = useSelector((state) => state.reports);
  const dispatch = useDispatch();

  useEffect(() => {
    const start = async () => {
      dispatch(reportsActions.fetchExcelReports());
    };
    start();
  }, []);

  return (
    <Paper className={classes.root}>
      <ModalCreate />
      <ModalDelete />
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">Reportes Excel</Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => dispatch(reportsActions.setCreateExcelModal(true))}
                color="primary"
                variant="contained"
                size="small"
                startIcon={<AddCircleOutlineIcon />}
              >
                Crear reporte manual
              </Button>
            </Grid>
          </Grid>
          <hr></hr>
          <ReportsList />
        </CardContent>
      </Card>
    </Paper>
  );
}
