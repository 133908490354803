import React from "react";
import {
  Grid,
  Button,
  Avatar,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../redux/actions/auth";

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 256,
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
  avatar: {
    width: 100,
    height: 100,
    margin: "auto",
  },
}));

export default function ProfileCard() {
  const styles = useStyles();
  const [hover, setHover] = React.useState(false);

  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  return (
    <div className={styles.card}>
      <div>
        <Avatar
          className={styles.avatar}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          style={{
            border: hover ? "2px solid #303f9f" : "2px solid #fff",
            opacity: hover ? 0.5 : 1,
          }}
          src={"https://image.flaticon.com/icons/svg/747/747376.svg"}
        />
        {hover && <Typography variant="body2">cambiar imagen</Typography>}
      </div>
      <Typography variant="h6">{app.user?.name}</Typography>
      <Typography variant="body2" style={{ marginBottom: 6 }}>
        {app.user?.email}
      </Typography>
      <Divider light />
      <Grid
        container
        style={{
          padding: 10,
        }}
      >
        <Grid item xs={12}>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <Button startIcon={<PersonIcon />} fullWidth>
              Perfil
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<ExitToAppIcon />}
            fullWidth
            onClick={() => dispatch(authActions.logout())}
          >
            Cerrar sesión
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
