import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Paper,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  LocalShipping as RemovalsIcon,
  Assignment as ReportsIcon,
  Settings as ProfileIcon,
  Sync as SensorsIcon,
  Eco as EcoIcon,
  People as PeopleIcon,
  LocalShipping as TransIcon,
} from "@material-ui/icons";

import logo from "../../resources/logos/logo-color.png";

import appActions from "../../redux/actions/app";

const useStyles = makeStyles((_theme) => ({
  mobileList: {
    width: "250px",
    height: "100%",
  },
  toolbarLogo: {
    height: "60px",
    margin: "10px",
  },
}));

const items = {
  ADMIN: [
    {
      text: "Home",
      to: "/admin/",
      icon: <HomeIcon />,
    },
    {
      text: "Retiros",
      to: "/removals",
      icon: <RemovalsIcon />,
    },
    {
      text: "Clientes",
      to: "/customers",
      icon: <EcoIcon />,
    },
    {
      text: "Reportes",
      to: "/reports",
      icon: <ReportsIcon />,
    },
    {
      text: "Reportes Excel",
      to: "/excelReports",
      icon: <ReportsIcon />,
    },
    {
      text: "Transportistas",
      to: "/transporters",
      icon: <TransIcon />,
    },
    {
      text: "Sensores",
      to: "/sensors",
      icon: <SensorsIcon />,
    },
    {
      text: "Plantas",
      to: "/dispositions",
      icon: <EcoIcon />,
    },
    {
      text: "Aprendizaje",
      to: "/learning",
      icon: <ReportsIcon />,
    },
    {
      text: "Usuarios",
      to: "/users",
      icon: <PeopleIcon />,
    },
  ],
  EMPTY: [],
};

export default function Menu() {
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);

  return (
    <Fragment>
      {auth.loged === "LOGED" && app.isMobile && <Mobile />}
      {auth.loged === "LOGED" && !app.isMobile && <Desktop />}
    </Fragment>
  );
}

function Mobile() {
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const switchMenu = (open) => dispatch(appActions.switchMenu(open));

  return (
    <Fragment>
      {app.isOpenMenu && (
        <Paper
          className={classes.mobileList}
          onClick={() => switchMenu(false)}
          onKeyDown={() => switchMenu(false)}
        >
          <Grid container item xs={12} justify="center">
            <img
              className={classes.toolbarLogo}
              src={logo}
              alt="Acción Circular"
            />
          </Grid>
          <Divider />
          <List>
            {items["ADMIN"].map((element) => (
              <NavLink
                exact={true}
                to={element.to}
                key={"menu_item_" + element.text}
                strict
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem>
                  <ListItemIcon>{element.icon}</ListItemIcon>
                  <ListItemText>{element.text}</ListItemText>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Paper>
      )}
    </Fragment>
  );
}

function Desktop() {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  return (
    <div
      className="sidebar-fixed position-fixed"
      style={{
        paddingTop: "2%",
        marginLeft: 12,
      }}
    >
      <Paper>
        <List>
          {items["ADMIN"].map((element) => (
            <NavLink
              exact={true}
              to={element.to}
              key={"menu_item_" + element.text}
              strict
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem selected={location.pathname === element.to}>
                <ListItemIcon>{element.icon}</ListItemIcon>
                <ListItemText>{element.text}</ListItemText>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Paper>
    </div>
  );
}
