import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import logo from "../../resources/images/logo.png";
import Fade from "react-reveal/Fade";
import { useAppContext } from "../../context/AppContext";

const useStyles = makeStyles(({ breakpoints }) => ({
  welcomeLogo: {
    height: 180,
    [breakpoints.down("md")]: {
      height: 124,
      width: "100%",
    },
  },
}));

export default function DialogLogin() {
  const styles = useStyles();
  const { user } = useAppContext();

  return (
    <Dialog
      open={true}
      onClose={null}
      maxWidth="lg"
      fullWidth
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent>
        <Fade>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <img
                className={styles.welcomeLogo}
                src={logo}
                alt="Acción Circular"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                align="center"
                style={{ color: "white" }}
              >
                Bienvenid@ {user?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!user?.activate && (
                <Typography
                  variant="h5"
                  align="center"
                  style={{ color: "white" }}
                >
                  Debes activar tu cuenta para ingresar
                </Typography>
              )}
            </Grid>
          </Grid>
        </Fade>
      </DialogContent>
    </Dialog>
  );
}
