import React from "react";
import {
  Dialog,
  Grid,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

// import { deleteTransporters } from "../../../api/transportersApi";
import transportersActions from "../../../redux/actions/transporters";

export default ({ 
  transporters,
  transporter, 
  // setTransporter 
}) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(transportersActions.deleteTransporter({ transporterID: transporter._id}))
    handleClose();
  };

  const handleClose = async () => {
    dispatch(transportersActions.setDeleteModal(false));
  };

  return (
    <Dialog open={transporters.deleteModal}>
      <DialogContent>
        <Grid item>
          <div>
            <h4>Confirmar Eliminación</h4>
          </div>
        </Grid>
        <hr></hr>
        <DialogContentText>
          {`Estás por eliminar al transportista ${
            transporter ? transporter.name : ""
          }
          ${transporter ? transporter.lastName : ""}. ¿Estas Segur@?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button color="primary" onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button onClick={() => handleDelete()} color="secondary">
            Confirmar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};
