import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";

import truck_recycling from "../../../../resources/images/truck_recycling.png"
import truck_garbage from "../../../../resources/images/truck_garbage.png"

export default function Symbology() {
  return (
    <Grid container>
      <Grid item>
        <Card style={{ padding: 6, maxWidth: 260 }}>
          <Typography component="h5" align="center">
            Simbología
          </Typography>
          <hr />
          <Grid container>
            <Grid item xs={4} align="center">
              <img src={truck_garbage} style={{ width: "30%" }} />
            </Grid>
            <Grid item xs={8} align="center">
              <Typography variant="body2" color="textSecondary" align="left">
                Planta de eliminación
              </Typography>
            </Grid>
            <Grid item xs={4} align="center">
              <img src={truck_recycling} style={{ width: "30%" }} />
            </Grid>
            <Grid item xs={8} align="center">
              <Typography variant="body2" color="textSecondary" align="left">
                Planta valorizadora
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

