const defaultState = {
  transporters: [],
  createModal: false,
  deleteModal: false,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "TRANSPORTERS_FETCH": {
      return { ...state, transporters: payload};
    }
    case "TRANSPORTERS_FETCH_FILLED": {
      return { ...state, transporters: payload };
    }
    case "TRANSPORTERS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "TRANSPORTERS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "RESET": {
      return { ...state, transporters: [] };
    }
    default:
      return state;
  }
}

export default reducer;