const defaultState = {
    list: [],
    filled:[],
    table:[],
    location:[],
    configuration:[],
    localID: "EMPTY",
    customerID: "EMPTY",
    model: "EMPTY",
};

function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case "SENSORS_FETCH": {
            return { ...state, list: payload, table: [], location: [], configuration:[], filled: [] };
        }
        case "SENSORS_SET_LOCALID": {
            return { ...state, localID: payload.localID, customerID: "EMPTY" , model:"EMPTY"};
        }
        case "SENSORS_SET_MODEL": {
            return { ...state, model: payload.model, customerID: "EMPTY", localID: "EMPTY"};
        }
        case "SENSORS_SET_CUSTOMERID": {
            return { ...state, customerID: payload.customerID, localID: "EMPTY",model:"EMPTY"  };
        }
        case "SENSORS_SET_MATERIAL": {
            return { ...state, configuration : payload};
        }
        case "SENSORS_FETCH_FILLED": {
            return { ...state, filled: payload, table: [],configuration:[], location: [] };
        }
        case "SENSORS_FETCH_FILLED_SEDE": {
            return { ...state, filled: payload, table: [],configuration:[], location: []};
        }
        case "SENSORS_TABLE_BY_LOCAL": {
            return { ...state, table: payload, filled: [],configuration:[], location: [] };
        }
        case "SENSORS_TABLE_BY_SEDE": {
            return { ...state, table: payload, filled: [],configuration:[], location: [] };
        }
        case "SENSORS_TABLE_BY_MODEL": {
            return { ...state, table: payload, filled: [],configuration:[], location: [] };
        }
        case "SENSORS_TABLE_BY_FILL": {
            return { ...state, table: payload, filled: [],configuration:[], location: [] };
        }
        case "SENSORS_LOCATION_BY_LOCAL": {
            return { ...state, location: payload, filled: [],configuration:[], table:[] };
        }
        case "SENSORS_LOCATION_BY_SEDE": {
            return { ...state, location: payload, filled: [],configuration:[], table:[] };
        }
        case "SENSORS_CONFIGURATION_BY_LOCAL": {
            return { ...state, configuration: payload, filled: [],location:[], table:[] };
        }
        case "RESET": {
            return { ...state, location: [], filled: [], table:[],configuration:[], list:[], model: "EMPTY", customerID: "EMPTY", localID: "EMPTY" };
        }
        default:
            return state;
    }
}

export default reducer;