import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import Costumers from "./Customers";
import Locals from "./Locals";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import DomainIcon from "@material-ui/icons/Domain";
import { useCustomersContext } from "../../../../context/CustomersContext";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";

export default function ModalCreate() {
  const { setViewModalCreateCustomer, createCustomer } = useCustomersContext();
  const [step, setStep] = React.useState(0);
  const [status, setStatus] = React.useState(0);
  const [localsData, setLocalsData] = React.useState([]);

  const [formData, setFormData] = React.useState({
    brand: "",
    rut: "",
    businessName: "",
    businessActivity: "",
    addressSociety: "",
    legalRepresentName: "",
    legalRepresentRut: "",
  });

  React.useEffect(() => {
    if (step === 0) {
      if (
        !formData.brand ||
        !formData.rut ||
        !formData.businessName ||
        !formData.businessActivity ||
        !formData.addressSociety ||
        !formData.legalRepresentName ||
        !formData.legalRepresentRut
      ) {
        setStatus(0);
      } else {
        setStatus(1);
      }
    }
    if (step === 1) {
      if (!localsData.length) {
        setStatus(0);
      } else {
        setStatus(1);
      }
    }
  }, [formData, localsData, step]);

  const handleClose = () => {
    setViewModalCreateCustomer(false);
  };

  const handleNext = () => {
    if (step === 0 && status === 1) {
      setStatus(0);
      setStep(step + 1);
    } else if (step === 1 && status === 1) {
      createCustomer({ customer: formData, locals: localsData }).then(
        (_response) => {
          setViewModalCreateCustomer(false);
        }
      );
    } else {
      return;
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <h4>{step === 0 ? "Registrar cliente" : "Registrar locales"}</h4>
          </Grid>
          <Grid item>
            <ChromeReaderModeIcon
              style={{ color: step === 0 ? "#00C851" : "#2E2E2E" }}
            />{" "}
            <DomainIcon style={{ color: step === 1 ? "#00C851" : "#BDBDBD" }} />
          </Grid>
        </Grid>
        {step === 0 && (
          <Costumers formData={formData} setFormData={setFormData} />
        )}
        {step === 1 && (
          <Locals
            setLocalsData={setLocalsData}
            localsData={localsData}
            formDataIndex={formData}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small">
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            startIcon={<CloseIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={step === 1 ? () => setStep(0) : null}
            color={step ? "secondary" : ""}
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
          >
            atrás
          </Button>
          <Button
            onClick={handleNext}
            color={status ? "secondary" : ""}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            {step === 0 ? "siguiente" : "crear"}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
