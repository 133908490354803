import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import validator from "email-validator";
import { format } from "rut.js";
import axios from "axios";

import materialsList from "../../../../resources/materials";
import functions from "../../../../resources/functions";

export default function General({
  dispositionData,
  // setDispositionData,
  formData,
  setFormData,
  updateData,
  communes,
  communeSelected,
  setCommuneSelected,
  treatmentType,
  setTreatmentType,
  typeOfTreatmentsOptions,

  typesOfTreatmentsSelected,
  setTypesOfTreatmentsSelected,

  materialsSelected,
  setMaterialsSelected,
  error,
}) {

  const [addressOptions, setAddressOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isManualPosition, setIsManualPosition] = useState(false);
  const [manualPosition, setManualPosition] = useState("");
  const [errorManualPosition, setErrorManualPosition] = useState(false);

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };

  const materials = materialsList.materials;
  const loading = open && addressOptions.length === 0 && formData.address !== "";
  const materialsOptions = Object.values(materials).map((material) => ({
    name: material.name,
    code: material.key.toUpperCase()
  }));

  useEffect(() => {
    if (errorManualPosition) {
      setTimeout(() => {
        setErrorManualPosition(false);
      }, 1500);
    }
  }, [errorManualPosition]);

  useEffect(() => {
    if (formData.address === "") {
      return undefined;
    }
    (async () => {
      const endpoint = process.env.REACT_APP_API_MAPBOX;
      const token = process.env.REACT_APP_MAPGL_API_KEY;

      let timeout = 0
      if (timeout) clearTimeout(timeout);
      const query = encodeURIComponent(formData.address + ".json");
      const previus = formData.address;

      if (previus !== formData.address) {
        return undefined;
      }
      timeout = setTimeout(async () => {
        const response = await axios.get(endpoint + query, {
          params: { access_token: token },
        });
        const payload = await response.data.features;
        setAddressOptions(payload.map((result) => {
          if (result.length === 0) return
          return {
            name: result.place_name,
            coords: {
              lat: result.center[1],
              long: result.center[0],
            },
            address: result.place_name,
          };
        })
        );
      }, 2500);
    })();
  }, [formData.address]);

  useEffect(() => {
    setFormData({
      _id: dispositionData?._id,
      name: dispositionData?.name,
      rut: dispositionData?.rut,
      contactName: dispositionData?.contactName,
      email: dispositionData?.email,
      phone: dispositionData?.phone,
      address: dispositionData?.address,
      position: dispositionData?.position,
      commune: dispositionData?.commune,
      region: dispositionData?.region,
      province: dispositionData?.province,
      typeOfTreatment: dispositionData?.typeOfTreatment,
      materialsID: dispositionData?.materialsID,
    });
  }, [dispositionData]);

  const formatPhone = (phone) => {
    return functions.formatPhone(phone);
  };

  const formatRuc = (rut) => {
    return format(rut);
  };

  const handleRegProvComsForm = (event) => {
    setCommuneSelected(event);
    setFormData({
      ...formData,
      commune: event.code,
      region: event.regCode,
      province: event.provCode
    });
  };

  const handleTypeOfTreatment = (event) => {
    setTypesOfTreatmentsSelected(event);
    setFormData({
      ...formData,
      typeOfTreatment: [event]
    });
  };

  const handleAddress = (k) => {
    setFormData({
      ...formData,
      address: k.address,
      position: {
        latitude: k.coords.lat,
        longitude: k.coords.long
      }
    });
  };

  const handleTreatmentType = (value) => {
    setTreatmentType(value)
    setFormData({
      ...formData,
      typeOfTreatment: []
    })
  };

  const handleMaterialSelected = (event) => {
    setMaterialsSelected([...event]);
  };

  const handleManualPositions = () => {
    if (manualPosition.length < 22) { return setErrorManualPosition(true) }
    else {
      let latitudeAux = manualPosition.toString().slice(0, 10);
      let longitudeAux = manualPosition.toString().slice(11, 22);
      setFormData({
        ...formData,
        position: {
          latitude: parseFloat(latitudeAux),
          longitude: parseFloat(longitudeAux)
        }
      })
    }
  };

  function ItemCommune(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Coumna"
          value={formData.commune}
          error={error && !formData.commune}
        />
      </div>
    );
  };

  function ItemTypeOfTreatment(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Tratamientos"
          error={error && !formData.typeOfTreatment}
        />
      </div>
    );
  };

  function ItemMaterials(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Materiales"
          error={error && !formData.materialsID}
        />
      </div>
    );
  };

  function ItemAddress(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Dirección"
          onChange={(e) => setFormData({ ...formData, address: e.target.value })}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={!formData.address && error}
        />
      </div>
    );
  };

  return (
    <div>
      <hr></hr>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>Datos de la empresa</h5>
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Nombre empresa"
            value={formData.name}
            onChange={(event) => updateData(event.target.value, "name")}
            error={error && !formData.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Rut"
            value={formatRuc(formData.rut)}
            onChange={(event) => updateData(event.target.value, "rut")}
            error={error && !formData.rut}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Nombre de contacto"
            value={formData.contactName}
            onChange={(event) => updateData(event.target.value, "contactName")}
            error={error && !formData.contactName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Mail"
            value={formData.email}
            onChange={(event) => updateData(event.target.value, "email")}
            error={!validator.validate(formData.email) && error}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Teléfono"
            value={formatPhone(formData.phone)}
            onChange={(event) => updateData(event.target.value, "phone")}
            error={error && !formData.phone}
          />
        </Grid>
        <Grid item xs={6}>
          {!isManualPosition ?
            (<Autocomplete
              id="asynchronous-demo"
              open={open}
              onOpen={() => { setOpen(true) }}
              onClose={() => { setOpen(false) }}
              getOptionLabel={(option) => option.name}
              onChange={async (e, k) => handleAddress(k)}
              options={addressOptions}
              loading={loading}
              renderInput={(params) => ItemAddress(params)}
            />)
            :
            (<TextField
              {...style}
              name="address"
              label="Dirección"
              value={formData.address}
              onChange={(event) => updateData(event.target.value, "address")}
              error={!formData.address && error}
            />)
          }
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="commune"
            options={communes}
            disableClearable
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            value={communeSelected}
            renderInput={(params) => ItemCommune(params)}
            onChange={(_, k) => handleRegProvComsForm(k)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            select
            disabled
            label="Tipo de tratamiento"
            value={treatmentType}
            onChange={(event) => handleTreatmentType(event.target.value)}
          >
            <MenuItem value="TOT_ELI" >Eliminación</MenuItem>
            <MenuItem value="TOT_VAL">Valorización</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            id="multiple=typeOfTreatments"
            key={treatmentType}
            options={typeOfTreatmentsOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            filterSelectedOptions
            value={typesOfTreatmentsSelected}
            renderInput={(params) => ItemTypeOfTreatment(params)}
            onChange={(_,k) => handleTypeOfTreatment(k)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="Agregar coordenadas manual"
            control={
              <Checkbox
                checked={isManualPosition}
                onChange={(event) => setIsManualPosition(event.target.checked)}
              />
            }
          />
        </Grid>
        {isManualPosition &&
          <Grid container spacing={1}>
            <Grid item md={6} sm={6}>
              <TextField
                name="manualCoordinates"
                label="Latitud y longitud"
                placeholder="-33.436988,-70.634391"
                onChange={(e) => setManualPosition(e.target.value)}
                error={(!formData.position && !isManualPosition) || errorManualPosition}
                {...style}
              />
            </Grid>
            <Grid item md={6} sm={6}>
              <Button
                onClick={handleManualPositions}
                color={"primary"}
              >
                actualizar coordenadas
              </Button>
            </Grid>
          </Grid>
        }
        <hr></hr>
        <Grid item xs={12}>
          <h5>Materiales que recibe</h5>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="multiple=materials"
            options={materialsOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            filterSelectedOptions
            value={materialsSelected}
            renderInput={(params) => ItemMaterials(params)}
            onChange={(_, k) => handleMaterialSelected(k)}
          />
        </Grid>
      </Grid>
    </div>
  );
}
