import React from "react";
import { Grid, ButtonGroup, Button } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SearchIcon from "@material-ui/icons/Search";
export default function ViewCustomer({
  mainData,
  selectDate,
  setSelectDate,
  dateInit,
  dateFinish,
  setDateFinish,
  setDateInit,
}) {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <h3>Cliente {mainData.brand}</h3>
      </Grid>
      <Grid item>
        <ButtonGroup size="small" variant="contained">
          <Button
            color={selectDate === 2 ? "secondary" : "primary"}
            onClick={() => setSelectDate(2)}
          >
            Elegir intervalo
          </Button>
          <Button
            color={selectDate === 1 ? "secondary" : "primary"}
            onClick={() => setSelectDate(1)}
          >
            Histórico
          </Button>
          <Button
            color={selectDate === 0 ? "secondary" : "primary"}
            onClick={() => setSelectDate(0)}
          >
            Este mes
          </Button>
        </ButtonGroup>
      </Grid>
      {selectDate === 2 && (
        <Grid item container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={5}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                label="Fecha inicial"
                inputVariant="outlined"
                value={dateInit}
                onChange={setDateInit}
                margin="dense"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={5}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                label="Fecha final"
                inputVariant="outlined"
                value={dateFinish}
                onChange={setDateFinish}
                margin="dense"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              style={{ marginRight: 10, marginTop: 8, height: 40 }}
              onClick={() => setSelectDate(4)}
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
