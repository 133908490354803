import React from "react";
import { Grid, TextField, FormControlLabel, Checkbox, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GetAppIcon from "@material-ui/icons/GetApp";

import validator from "email-validator";
import { format } from "rut.js";

import functions from "../../../../resources/functions";

export default function General({
  formData,
  setFormData,
  updateData,
  communes,
  communeSelected,
  setCommuneSelected,
  error,
  fileValue,
  setFileValue,
  pdfError,
  setPdfError,
}) {

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };

  const formatPhone = (phone) => {
    return functions.formatPhone(phone);
  };

  const formatRuc = (rut) => {
    return format(rut);
  };

  const handleRegProvComsForm = (event) => {
    setCommuneSelected(event);
    setFormData({ ...formData, commune: event.code, region: event.regCode, province: event.provCode })
  };

  const handleLoadFile = (event) => {
    var files = event.target.files;
    var file = null;

    if (files[0].type === "application/pdf") {
      setPdfError(false);
      let fileReader = new FileReader();
      setFileValue(event.target.value);
      fileReader.onload = (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        setFormData({ ...formData, file });
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      setFileValue(null);
      setPdfError(true);
    }
  };

  function Item(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Comuna"
          value={formData.commune}
          error={error && !formData.commune}
        />
      </div>
    );
  };

  return (
    <div>
      <hr></hr>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5>Datos de empresa</h5>
        </Grid>
        <Grid item xs={6} >
          <TextField
            {...style}
            label="Nombre de los transportes"
            value={formData.company}
            onChange={(event) => updateData(event.target.value, "company")}
            error={error && !formData.company}
          />
        </Grid>
        <Grid item xs={6} >
          <TextField
            {...style}
            label="Rut del transportista"
            value={formatRuc(formData.rutEnterprise)}
            onChange={(event) => updateData(event.target.value, "rutEnterprise")}
            error={error && !formData.rutEnterprise}
          />
        </Grid>
        <Grid item xs={6} >
          <TextField
            {...style}
            label="Dirección"
            value={formData.address}
            type="text"
            onChange={(event) => updateData(event.target.value, "address")}
          />
        </Grid>
        <Grid item xs={6} >
          <Autocomplete
            id="commune"
            options={communes}
            disableClearable
            getOptionLabel={(option) => option.name}
            value={communeSelected}
            renderInput={(params) => Item(params)}
            onChange={(_, k) => handleRegProvComsForm(k)}
          />
        </Grid>
        <Grid item xs={12}>
          <hr></hr>
          <h5>Datos de contacto</h5>
        </Grid>
        <Grid item xs={6} >
          <TextField
            {...style}
            label="Nombre"
            value={formData.name}
            type="text"
            onChange={(event) => updateData(event.target.value, "name")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Apellido"
            value={formData.lastName}
            onChange={(event) => updateData(event.target.value, "lastName")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...style}
            label="Rut"
            value={formatRuc(formData.rut)}
            onChange={(event) => updateData(event.target.value, "rut")}
            error={error && !formData.rut}
          />
        </Grid>
        <Grid item xs={6} >
          <TextField
            {...style}
            label="Email"
            value={formData.email}
            onChange={(event) => updateData(event.target.value, "email")}
            error={error && !validator.validate(formData.email)}
          />
        </Grid>
        <Grid item xs={6} >
          <TextField
            {...style}
            margin="dense"
            label="Teléfono"
            value={formatPhone(formData.phone)}
            onChange={(event) => updateData(event.target.value, "phone")}
          />
        </Grid>
        <Grid item xs={6} >
          <FormControlLabel
            label="¿Tiene certificación?"
            control={
              <Checkbox
                checked={formData.certification}
                onChange={(event) =>
                  updateData(event.target.checked, "certification")
                }
                value={formData.certification}
              />
            }
          />
        </Grid>
        {formData.certificationURL &&
          <Grid item xs={12}>
            <Button
              onClick={() => window.open(formData.certificationURL)}
              color="primary"
            >
              Descargar certificación
            <GetAppIcon />
            </Button>
          </Grid>
        }
        <Grid item xs={12} >
          <TextField
            {...style}
            label="Certificación"
            value={formData.certificationPDF ? formData.certificationPDF : fileValue}
            onChange={handleLoadFile}
            type={formData.certificationPDF ? "text" : "file"}
            accept="pdf"
            disabled={!formData.certification}
            error={pdfError}
            helperText={pdfError && "Debe ser un archivo pdf."}
            onClick={formData.certificationPDF ? () => window.open(formData.certificationPDF) : null}
          />
        </Grid>
        {/* <Grid item xs={6} >
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="cat-sel">Categorias</InputLabel>
            <ResidueSelect
              value={formData.categories}
              menuProps={MenuProps}
              onChange={handleCategoryChange}
              chipClassName={classes.chip}
            />
          </FormControl>
        </Grid> */}
      </Grid>
    </div>
  );
}


