import React from "react";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Card,
  Typography,
  Button,
} from "@material-ui/core";
import Charts from "./Charts";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import BarChartIcon from "@material-ui/icons/BarChart";
import { useSensorsContext } from "../../../../context/SensorsContext";
import Nothing from "./Nothing";

export default function Container({ filled, local }) {
  const [data, setData] = React.useState([]);
  const [removals] = React.useState([]);
  const [options, setOptions] = React.useState(null);
  const [view, setView] = React.useState(false);
  const { helpers } = useSensorsContext();

  React.useEffect(() => {
    if (filled.length) {
      let temp = {};
      Object.keys(filled[0]).forEach((key) => {
        temp[key] = true;
      });
      delete temp.date;
      setOptions(temp);
    }
  }, [filled]);

  React.useEffect(() => {
    if (filled.length && options) {
      let aux = [];
      filled.forEach((fill) => {
        let charts = { ...fill };
        delete charts.date;
        Object.keys(options).forEach((option) => {
          if (!options[option]) {
            delete charts[option];
          }
          return null;
        });
        charts = {
          ...charts,
          name: fill.date,
        };
        aux.push(charts);
        return null;
      });
      setData(aux);
    }
  }, [filled, options]);

  const handleChange = ({ e, option }) => {
    setOptions({ ...options, [option]: e.target.checked });
  };

  return (
    <Card style={{ padding: 12, margin: 6 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item>
          <Button
            color="primary"
            variant="text"
            onClick={() => setView(!view)}
            startIcon={<BarChartIcon />}
          >
            {local.name}
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={() => setView(!view)}>
            {!view ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Button>
        </Grid>
      </Grid>

      {view && Boolean(filled.length) && (
        <Grid container direction="row" justify="center">
          <Grid xs={12}>
            <hr></hr>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1" align="left">
              Materiales
            </Typography>
            <hr />
          </Grid>
          <Grid item xs={11} container>
            {options &&
              Object.keys(options).map((option, i) => (
                <Grid item key={"checkbox_" + i} xs={4} sm={3} align="left">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(options[option])}
                        color="primary"
                        onChange={(e) => handleChange({ e, option })}
                        name={option.value}
                      />
                    }
                    label={helpers[option].name}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid item xs={12}>
            <Charts data={data} helpers={helpers} removals={removals} />
          </Grid>
        </Grid>
      )}
      {!filled.length && view && <Nothing text="No se registran datos" />}
    </Card>
  );
}

Container.propTypes = {
  filled: PropTypes.array.isRequired,
  local: PropTypes.object.isRequired,
};
