import React from "react";
import { Grid } from "@material-ui/core";
import { MDBBtn, MDBBtnGroup } from "mdbreact";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre"
];

export default function Dates({
  setDateInit,
  setDateFinish,
  dateInit,
  dateFinish
}) {
  const [selectDate, setSelectDate] = React.useState(0);

  React.useEffect(() => {
    const now = new Date();

    if (selectDate === 0) {
      setDateInit(new Date(now.getFullYear(), now.getMonth(), 1));
      setDateFinish(now);
    }
    if (selectDate === 1) {
      setDateInit(new Date(2018, 1, 1));
      setDateFinish(now);
    }
  }, [selectDate]);

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        {selectDate === 0 ? (
          <h3>Estadísticas de {months[new Date().getMonth()]}</h3>
        ) : selectDate === 1 ? (
          <h3>Estadísticas históricas</h3>
        ) : (
          <h3>Estádisticas en intervalo</h3>
        )}
      </Grid>
      <Grid item>
        <MDBBtnGroup size="sm">
          <MDBBtn
            color={selectDate === 2 ? "success" : "white"}
            onClick={() => setSelectDate(2)}
          >
            Elegir intervalo
          </MDBBtn>
          <MDBBtn
            color={selectDate === 1 ? "success" : "white"}
            onClick={() => setSelectDate(1)}
          >
            Histórico
          </MDBBtn>
          <MDBBtn
            color={selectDate === 0 ? "success" : "white"}
            onClick={() => setSelectDate(0)}
          >
            Este mes
          </MDBBtn>
        </MDBBtnGroup>
      </Grid>
      {selectDate === 2 && (
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Fecha inicial"
                  inputVariant="outlined"
                  value={dateInit}
                  onChange={setDateInit}
                  margin="dense"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Fecha final"
                  inputVariant="outlined"
                  value={dateFinish}
                  onChange={setDateFinish}
                  margin="dense"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2}>
              <MDBBtn
                size="sm"
                color="success"
                style={{ marginRight: 50, marginTop: 8 }}
                onClick={() => setSelectDate(4)}
              >
                Buscar
              </MDBBtn>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
