import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  ButtonGroup,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import userActions from "../../../redux/actions/users";
import SecurityIcon from "@material-ui/icons/Security";

function ResponsiveDialog() {
  const dispatch = useDispatch();
  const utils = useSelector((state) => state.utils);
  const users = useSelector((state) => state.users);
  const [transportista, setTransportista] = useState();

  const [error, setError] = useState(false);
  const [dataSelectEntity, setDataSelectEntity] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    entityID: "EMPTY",
    type: "CUSTOMER",
    phone: "",
    activate: false,
    transporterID: null
  });

  const [transporter, setTransporter] = useState([]);

  useEffect(() => {
    
    if (formData.type === "CUSTOMER") {
      setDataSelectEntity(
        utils.customers.map((customer) => {
          return {
            name: customer.brand,
            _id: customer._id,
          };
        })
      );
    } else if (formData.type === "LOCAL") {
      setDataSelectEntity(
        utils.locals.map((local) => {
          return {
            name: local.customerID.brand + " " + local.name,
            _id: local._id,
          };
        })
      );
    } else if (formData.type === "TRANSPORTER") {
      
      setDataSelectEntity(
        utils.transporterlist.transporters.map((local) => {
          return {
            name: local.name + " " + local.lastName,
            transporterID: local._id,
          };
        })
      );
    }
  }, [formData.type]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const updateData = async(e) => {
    if (e.target.name === "email") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      console.log(formData)
      
     
        console.log(transporter)
    }
  };

  const passwordGen = () => {
    const password = Math.round(Math.random() * 10000000 + 10000000);
    setFormData({ ...formData, password });
  };

  const handleSuccess = () => {
    if (
      (formData.entityID === "EMPTY"&&formData.type != "TRANSPORTER") ||
      !validateEmail(formData.email) ||
      formData.password.length < 7 ||
      formData.name === "" ||
      formData.lastName === "" 
    ) {
      setError(true);
    } else {
      if (formData.type === "TRANSPORTER") {
        setFormData({ ...formData, entityID: formData.transporterID })
      }
      dispatch(userActions.assignUserToEntity(formData));
      handleClose();
    }
  };


  const handleClose = () => {
    dispatch(userActions.setDataModal(null));
    dispatch(userActions.setCreateModal(false));
  };

  return (
    <Dialog
      open={users.createModal}
      onClose={() => handleClose()}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Typography variant="h5">Crear usuario</Typography>
        <hr></hr>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="type"
              value={formData.type}
              defaultValue="CUSTOMER"
              onChange={(e) => updateData(e)}
              select
              margin="dense"
              label="Tipo de usuario"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && !formData.type}
            >
              <MenuItem value="CUSTOMER">Cliente</MenuItem>
              <MenuItem value="LOCAL">Local</MenuItem>
              <MenuItem value="TRANSPORTER">Transportista</MenuItem>
            </TextField>
          </Grid>
          {formData.type === "TRANSPORTER" ? 
          <Grid item xs={12} sm={6}>
          <TextField
          name="transporterID"
          value={formData.transporterID}
          onChange={(e) => updateData(e)}
          select
          margin="dense"
          label="Transportistas"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          /* error={error && formData.entityID === "EMPTY"} */
        >
          <MenuItem value="EMPTY">
                <em>
                  Selecciona un{" "}
                  {formData.type === "TRANSPORTER" ? "transportista" : ""}
                </em>
              </MenuItem>
              {dataSelectEntity.map((element, i) => (
                <MenuItem value={element.transporterID} key={i} >
                  {element.name}
                </MenuItem>
              ))}
        </TextField>
      </Grid>
          
          :
          <Grid item xs={12} sm={6}>
            <TextField
              name="entityID"
              value={formData.entityID}
              onChange={(e) => updateData(e)}
              select
              margin="dense"
              label={
                formData.type === "CUSTOMER" ? "Cliente" : "Sede / sucursal"
              }
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && formData.entityID === "EMPTY"}
            >
              <MenuItem value="EMPTY">
                <em>
                  Selecciona un{" "}
                  {formData.type === "CUSTOMER" ? "cliente" : "sede / sucursal"}
                </em>
              </MenuItem>
              {dataSelectEntity.map((element, i) => (
                <MenuItem value={element._id} key={i}>
                  {element.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>}
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              value={formData.email}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Email"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={
                error && (!formData.email || !validateEmail(formData.email))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              value={formData.password}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Password"
              type={"text"}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && formData.password.length < 7}
            />
            <Button
              variant="text"
              color="secondary"
              size="small"
              startIcon={<SecurityIcon />}
              onClick={() => passwordGen()}
            >
              generar contraseña
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              value={formData.name}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Nombre"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && !formData.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="lastName"
              value={formData.lastName}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Apellido"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && !formData.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone"
              value={formData.phone}
              onChange={(e) => updateData(e)}
              margin="dense"
              label="Teléfono"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              error={error && !formData.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.activate}
                  onChange={(e) =>
                    setFormData({ ...formData, activate: e.target.checked })
                  }
                  color="primary"
                />
              }
              label="Crear usuario activado"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button onClick={() => handleClose()} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => handleSuccess()} color="primary">
            Crear usuario
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}

export default ResponsiveDialog;
