import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

const getCustomers = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/adminTool/customers", {
        params: {},
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const getLocals = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/adminTool/locals", {
        params: {},
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const getDataCreateLocal = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/adminTool/locals/getDataCreateLocal", {
        params: {},
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const updateCustomer = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        apiUrl + "/adminTool/customers",
        {
          ...data,
        },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const createCustomer = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        apiUrl + "/adminTool/customers",
        {
          ...data,
        },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const deleteCustomer = (customerID) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(apiUrl + "/adminTool/customers", {
        params: { customerID },
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const updateLocal = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        apiUrl + "/adminTool/locals",
        {
          ...data,
        },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const createLocal = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        apiUrl + "/adminTool/locals",
        {
          ...data,
        },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const deleteLocal = (localID) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(apiUrl + "/adminTool/locals", {
        params: { localID },
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
const getStats = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiUrl + "/adminTool/customers/stats", {
        params: { ...data },
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export {
  createCustomer,
  getCustomers,
  getLocals,
  getDataCreateLocal,
  createLocal,
  updateLocal,
  updateCustomer,
  deleteCustomer,
  deleteLocal,
  getStats,
};
