import React, { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login";

import Reports from "./Pages/Reports";
import ExcelReports from "./Pages/ExcelReports";
import Removals from "./Pages/Removals";
import Transporters from "./Pages/Transporters";
import Users from "./Pages/Users";
import Dashboard from "./Pages/Dashboard";
import Customers from "./Pages/Customers";
import Sensors from "./Pages/Sensors";
import Dispositions from "./Pages/Dispositions";
import Suscriptions from "./Pages/Suscriptions";
import UserIsAuthenticated from "./Auth";
import Learning from "./Pages/Learning";

export default function Routes() {
  return (
    <Fragment>
      <UserIsAuthenticated>
        <Switch>
          <Route path="/" exact render={() => <Dashboard />} />
          <Route path="/reports" render={() => <Reports />} />
          <Route path="/excelReports" render={() => <ExcelReports />} />
          <Route path="/removals" render={() => <Removals />} />
          <Route path="/transporters" render={() => <Transporters />} />
          <Route path="/customers" render={() => <Customers />} />
          <Route path="/users" render={() => <Users />} />
          <Route path="/sensors" render={() => <Sensors />} />
          <Route path="/sensors" render={() => <Sensors />} />
          <Route path="/dispositions" render={() => <Dispositions />} />
          <Route path="/learning" render={() => <Learning />} />
          {/* <Route render={() => <Redirect to="/" />} /> */}
        </Switch>
      </UserIsAuthenticated>
      <Route path="/login" render={() => <Login />} />
    </Fragment>
  );
}
