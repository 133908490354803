const defaultState = {
  loged: "",
  userType: "ADMIN",
  loginStatus: {
    step: "READY",
    error: false,
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "AUTH_LOGIN_SUCCESS": {
      return { ...state, loged: "LOGED" };
    }
    case "AUTH_LOGIN_FAIL": {
      return { ...state, loged: "NOT_LOGED" };
    }
    case "AUTH_LOGOUT": {
      return { ...state, loged: "NOT_LOGED" };
    }
    case "AUTH_SET_LOGIN_STATUS": {
      return { ...state, loginStatus: payload };
    }
    default:
      return state;
  }
}

export default reducer;
