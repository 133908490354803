import React, { useState } from "react";
import ReactMapGL, {
  Marker,
  Popup,
} from "react-map-gl";  // ONLY USE v5.2.11

import DispositionPin from "./DispositionPin";
import DispositionInfo from "./DispositionInfo";

const token = process.env.REACT_APP_MAPGL_API_KEY;

export default function Index({ formData }) {
  const [info, setInfo] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: -33.418948,
    longitude: -70.577940,
    zoom: 15,
  });

  React.useEffect(() => {
    setViewport({
      ...viewport,
      latitude: parseFloat(formData.position?.latitude)
        ? parseFloat(formData.position?.latitude)
        : 0,
      longitude: parseFloat(formData.position?.longitude)
        ? parseFloat(formData.position?.longitude)
        : 0,
    })
  }, [formData.position]);

  function RenderPopup() {
    return (info && (
      <Popup
        tipSize={5}
        anchor="top"
        longitude={info.position.longitude}
        latitude={info.position.latitude}
        closeOnClick={false}
        onClose={() => setInfo(null)}
      >
        <DispositionInfo info={info} />
      </Popup>
    ));
  }

  return (
    <ReactMapGL
      mapboxApiAccessToken={token}
      {...viewport}
      width="100%"
      height="90vh"
      mapStyle={"mapbox://styles/mapbox/light-v10"}
      onViewportChange={(viewport) => setViewport(viewport)}
      style={{ border: "2px solid #003398" }}
    >
      {formData.position &&
        <Marker
          latitude={formData.position?.latitude}
          longitude={formData.position?.longitude}
        >
          <DispositionPin size={20} onClick={() => setInfo(formData)} data={formData} />
        </Marker>
      }
      {RenderPopup()}
    </ReactMapGL>
  );
}