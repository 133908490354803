import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import regProvComsData from "../../../../resources/regProvCom";
import dispositionsActions from "../../../../redux/actions/dispositions";

import General from "./General";

const initState = {
  name: "",
  rut: "",
  contactName: "",
  email: "",
  phone: "",
  address: "",
  position: {
    latitude: -33.436988,
    longitude: -70.634391
  },
  commune: "13114",
  region: "CL-RM",
  province: "Santiago",
  typeOfTreatment: [],
  materialsID: [],
};

const initRegProvCom = {
  name: "Las Condes",
  code: "13114",
  regCode: "CL-RM",
  provCode: "Santiago",
}

const initTypeOfTreatment = {
  name: "",
  code: "",
  subTypeName: "",
  subTypeCode: "",
}

const initTreatmentType = "TOT_ELI"

const CreateModal = ({ createModal, setCreateModal, typeOfTreatments }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [communes] = useState([]);
  const [formData, setFormData] = useState(initState);
  const [communeSelected, setCommuneSelected] = useState(initRegProvCom);
  const [typesOfTreatmentSelected, setTypeOfTreatmentSelected] = useState([initTypeOfTreatment]);
  const [treatmentType, setTreatmentType] = useState(initTreatmentType);
  const [typeOfTreatmentsOptions, setTypeOfTreatmentsOptions] = useState([]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  // REGIONES PROVINCIAS Y COMUNAS
  useEffect(() => {
    regProvComsData.forEach(regionsElements => {
      regionsElements.provincias.forEach(provinceElement => {
        provinceElement.comunas.forEach(communeElement => {
          communes.push({
            name: communeElement.name,
            code: communeElement.code,
            regCode: regionsElements.region_iso_3166_2,
            provCode: provinceElement.name,
          })
        })
      })
    })
    communes.sort((x, y) => {
      if (x.name > y.name) {
        return 1;
      }
      if (x.name < y.name) {
        return -1;
      }
      return 0;
    })
    formData.commune = initRegProvCom.code;
    formData.region = initRegProvCom.regCode;
    formData.province = initRegProvCom.provCode;
  }, [])

  useEffect(() => {
    if (treatmentType === "TOT_ELI") {
      setTypeOfTreatmentsOptions(typeOfTreatments.filter(typeOfTreatment => typeOfTreatment.subTypeName === "Eliminación" && typeOfTreatment.estado === "1"))
    } else {
      setTypeOfTreatmentsOptions(typeOfTreatments.filter(typeOfTreatment => typeOfTreatment.subTypeName === "Valorización" && typeOfTreatment.estado === "1"))
    }
  }, [treatmentType])

  const updateData = (data, name) => {
    setFormData({ ...formData, [name]: data });
  };

  const handleCloseModal = () => {
    resetFormData();
    setCreateModal(false);
  };

  const resetFormData = () => setFormData(initState);

  const handleSend = () => {
    if (
      formData.name &&
      formData.rut &&
      formData.contactName &&
      formData.address &&
      formData.position &&
      formData.commune &&
      formData.region &&
      formData.province &&
      formData.typeOfTreatment &&
      formData.materialsID
    ) {
      console.log("IF");
      dispatch(dispositionsActions.postDisposition(formData));
      handleCloseModal();
    } else {
      console.log("ELSE");
      alert("Faltan campos por completar");
      setError(true);
    }
  };

  return (
    <Dialog open={createModal} onClose={handleCloseModal} scroll="body" maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4>
              Agregar nueva disposición final
            </h4>
          </Grid>
          <hr></hr>
          <General
            formData={formData}
            setFormData={setFormData}
            updateData={updateData}
            communes={communes}
            communeSelected={communeSelected}
            setCommuneSelected={setCommuneSelected}
            treatmentType={treatmentType}
            setTreatmentType={setTreatmentType}
            typeOfTreatmentsOptions={typeOfTreatmentsOptions}
            typesOfTreatmentSelected={typesOfTreatmentSelected}
            setTypeOfTreatmentSelected={setTypeOfTreatmentSelected}
            error={error}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="secondary"
            onClick={handleCloseModal}
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleSend()}
            color="primary"
            startIcon={<CheckIcon />}
          >
            Agregar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

CreateModal.propTypes = {
  open: PropTypes.bool,
};

export default CreateModal;
