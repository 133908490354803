import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import Locals from "./Locals";
// import { createLocal } from "../../../../api/costumersApi";
import { useCustomersContext } from "../../../../context/CustomersContext";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

export default function ModalCreate() {
  const { setViewModalCreateLocal, createLocal } = useCustomersContext();

  const [status, setStatus] = React.useState(0);
  const [localsData, setLocalsData] = React.useState([]);
  
  React.useEffect(() => {
    if (!localsData.length) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  }, [localsData]);

  const handleClose = () => {
    setViewModalCreateLocal(false);
  };

  const handleNext = () => {
    createLocal({ locals: localsData }).then((_response) => {
      setViewModalCreateLocal(false);
    });
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <h4>Agregar locales</h4>
          </Grid>
        </Grid>
        <Locals setLocalsData={setLocalsData} localsData={localsData} />
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            onClick={handleClose}
            color="primary"
            startIcon={<ClearIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleNext}
            color={status ? "secondary" : "default"}
            startIcon={<CheckIcon />}
          >
            crear
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
