import React, { Fragment, useEffect } from "react";
import { TextField, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import materialsList from "../../../../resources/materials";

const list_materials = materialsList.materials;


const TextFieldComponent = ({ disabled, value, onChange, name, label }) => {
  return (
    <TextField
      id="outlined-required"
      variant="outlined"
      margin="dense"
      disabled={disabled}
      value={value}
      name={name}
      label={label}
      onChange={onChange}
      type="number"
	  onInput = {(e) =>{
					e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
				}}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  );
};

function Materials({ disabled, setFormData, formData }) {
  const updateMaterials = (e) => {
    let temp = {
      ...formData.materials,
      [e.target.name]: e.target.value > 0 ? e.target.value : 0,
    };

    let aux = 0;
    Object.keys(temp).map((key) => {
      aux += temp[key] * 1;
    });

    setFormData({
      ...formData,
      materialsIcon: Boolean(aux),
      materials: temp,
    });
    
    
  };
  return (
    <Fragment>
      
      <Grid container spacing={2}>
        {formData.materials &&
          Object.keys(list_materials).map((material) => (
            <Grid item xs={6} sm={2} key={"material_" + material}>
              <TextFieldComponent
                name={material.toUpperCase()}
                label={list_materials[material].name}
                onChange={updateMaterials}
                disabled={disabled}
                value={formData.materials[material.toUpperCase()]}
              />
            </Grid>
          ))}
      </Grid>
      
    </Fragment>
  );
}

export default Materials;
