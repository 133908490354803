import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,Grid, MenuItem, Typography,
} from "@material-ui/core";
import Materials from "./Materials";
import General from "./General";
import Disp from "./Disp";
import Header from "./Header";
import { ButtonGroupAction,ButtonGroupAction2, ButtonSwitch, Notes } from "./Components";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import materialsList from "../../../../resources/materials";


export default function ResponsiveDialog() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const removals = useSelector((state) => state.removals);
  const utils = useSelector((state) => state.utils);
  const list_materials = materialsList.materials;
  const [with_materials, setWith_materials] = useState();

  const now = new Date();
  const [formData, setFormData] = useState({
    removalID: null,
    localID: null,
    transporterID: null,
    vehicleID: null,
    datetimeRemoval: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      1
    ),
    datetimeRequest: new Date(),
    payment: 0,
    materialsIcon: false,
    window: "A",
    dispositions: null,
    materials: null,
  });

  const [view, setView] = useState({
    notes: false,
    materials: false,
    buttonName: "crear",
    alert: false,
  });

  const handleWith_materials = (e) => {
    setWith_materials(e.currentTarget.value==1);
    
  };

  const [dialogContent, setDialogContent] = useState("");

  useEffect(() => {
    let temp = {};
    Object.keys(utils.materials).forEach((material) => {
      temp[material.toUpperCase()] = 0;
    });
    setFormData({ ...formData, materials: temp });
  }, []);

  // CUANDO ES ACTUALIZACIÓN
  useEffect(() => {
    const dataModal = removals.dataModal;
	console.log(dataModal);
    if (dataModal) {

      setView({ ...view, buttonName: "Actualizar" });
      var aux = {};
      var disp = [];
      var materialsIcon = false;
      dataModal.materials.map((material) => {
        if (material.quantity) {
          materialsIcon = true;
          if(material.quantity>0){
            setWith_materials(true);
          }
          if(material.disposition!=null){
            disp[material.material] = material.disposition; 
          }
          
        }
        aux[material.material] = material.quantity;
		//aux[material.material]= material.note;
      });
      
      setFormData({
        ...dataModal,
        removalID: dataModal._id,
        localID: dataModal.localID._id,
        transporterID: dataModal.transporterID
          ? dataModal.transporterID._id
          : null,
        dispositionID: dataModal.dispositionID,
        vehicleID: dataModal.vehicleID,
        report: dataModal.urlReport,
        materialsIcon,
        materials: aux,
        dispositions: disp,
		tipo:1
      });
    }
  }, [removals.dataModal]);

  const handleClose = () => {
    if (!view.alert) {
      dispatch(removalsActions.setDataModal(null));
      dispatch(removalsActions.setCreateModal(false));
    }
  };

  const timeout = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleSuccess = async () => {
	  const dataModal = removals.dataModal;
		setView({ ...view, alert: true });
     dialogContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    await timeout(2000);
    if (formData.localID !== null) {
      // convertir obejeto de materiales a arreglo de modelo de backend
	    var notas = {};
	   if(dataModal){		  
		   dataModal.materials.map((material) => {			   
				notas[material.material] = material.note;
			  });
	   }
		let materials = Object.keys(formData.materials).map((key) => {
			return {
			  material: key,
			  quantity: formData.materials[key],
			  //disposition: !formData.dispositions ? null : formData.dispositions[key]
			  note: !notas[key]? null : notas[key],
			  disposition: !formData.dispositions ? null : formData.dispositions[key],
			  code: list_materials[key.toLowerCase()].code,
			  typeResidue: list_materials[key.toLowerCase()].name,
			  residueName: list_materials[key.toLowerCase()].typeResidue, 
			};
		  });
      const response = await dispatch(
        removalsActions.patchRemovals({
          _id: formData.removalID,
          ...formData,
          includeMaterial: 1,
          materials,
        })
      );
      if (response) {
        if (
          formData.transporterID &&
          formData.payment &&
          formData.materialsIcon
        ) {
          dispatch(removalsActions.setSelector("COMPLETE"));
        } else {
          dispatch(removalsActions.setSelector("INCOMPLETE"));
        }
        dispatch(removalsActions.setDataModal(null));
        dispatch(removalsActions.setCreateModal(false));
      }
    }
    setView({ ...view, alert: false });
  };

  const handleVali = async () => {
	  const dataModal = removals.dataModal;
    //setView({ ...view, alert: false });
    dialogContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    await timeout(2000);
    if (formData.localID !== null) {
      // convertir obejeto de materiales a arreglo de modelo de backend
      /*let materials = Object.keys(formData.materials).map((key) => {
        return {
          material: key,
          quantity: formData.materials[key],
          disposition: !formData.dispositions[key] ? null : formData.dispositions[key]
        };
      });*/
	    var notas = {};
	   if(dataModal){		  
		   dataModal.materials.map((material) => {			   
				notas[material.material] = material.note;
			  });
	   }
		let materials = Object.keys(formData.materials).map((key) => {
			return {
			  material: key,
			  quantity: formData.materials[key],
			  //disposition: !formData.dispositions ? null : formData.dispositions[key]
			  note: !notas[key]? null : notas[key],
			  disposition: !formData.dispositions ? null : formData.dispositions[key],
			  code: list_materials[key.toLowerCase()].code,
			  typeResidue: list_materials[key.toLowerCase()].name,
			  residueName: list_materials[key.toLowerCase()].typeResidue, 
			};
		  });
      const response = await dispatch(
        removalsActions.valiRemovals({
          _id: formData.removalID,
          ...formData,
          includeMaterial: 1,
          materials,
        })
      );
      if (response) {
        if (
          formData.transporterID &&
          formData.payment &&
          formData.materialsIcon
        ) {
          dispatch(removalsActions.setSelector("COMPLETE"));
        } else {
          dispatch(removalsActions.setSelector("INCOMPLETE"));
        }
        dispatch(removalsActions.setDataModal(null));
        dispatch(removalsActions.setCreateModal(false));
      }
    }
	
    setView({ ...view, alert: false });
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll={"body"}
      maxWidth={"md"}
      fullWidth
      fullScreen={isMobile}
    >
      <div
        ref={(node) => {
          setDialogContent(node);
        }}
      >
        <DialogContent>
          <Header formData={formData} view={view} />
          <General formData={formData} setFormData={setFormData} />
          
		  {
		  /*<ButtonSwitch
            name="materials"
            setView={setView}
            view={view}
            textPositive="+ Agregar materiales"
            textNegative="- Ocultar materiales"
          />*/
		  }
		  <hr></hr>
		  <Typography variant="h6">Materiales en kilogramos </Typography>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
              <label variant="h6">¿Este conjunto de materiales se encontraba disponible?</label>
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel value="Si" control={<input
                type="radio"
                value="1"
                checked={with_materials === true}
                onChange={handleWith_materials}
                
              />} label="Sí" />&nbsp;
                <FormControlLabel value="No" control={<input
                type="radio"
                value="0"
                checked={with_materials === false}
                onChange={handleWith_materials}
              />} label="No" />
              </RadioGroup>
              
              
                
              
        </Grid>
        {with_materials && (
                <Materials
              disabled={
                !formData.localID ||
                !formData.transporterID ||
                !formData.payment
              }
              formData={formData}
              setFormData={setFormData}
            />
              )}              
          {with_materials && (
          <Disp
              disabled={
                !formData.localID ||
                !formData.transporterID ||
                !formData.payment
              }
              formData={formData}
              list_materials={list_materials}
              setFormData={setFormData}
            />
          )}
          <ButtonSwitch
            name="notes"
            setView={setView}
            view={view}
            textPositive="+ Agregar nota"
            textNegative="- Ocultar nota"
          />
          <br />
          {view.notes && (
            <Notes formData={formData} setFormData={setFormData} />
          )}
          {/* <Planta formData={formData} setFormData={setFormData}/> */}
        </DialogContent>
		
		<DialogActions>
		
		{removals.selector === "COMPLETE" && removals.dataModal ?
			  ( 
				  <ButtonGroupAction
					 handleClose={handleClose}
					 handleSuccess={handleSuccess}
					 handleVali={handleVali}
					view={view}
					formData={formData}
				  />
				
				)
			  : 
			  (
				  <ButtonGroupAction2
					 handleClose={handleClose}
					 handleSuccess={handleSuccess}
					view={view}
					formData={formData}
				  />
				
				)
			 
		  }
		  </DialogActions>
        
      </div>
    </Dialog>
  );
}
