import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Typography,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import materialsList from "../../../../resources/materials";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function DialogBody({ data }) {
  const classes = useStyles();

  const formData = data;
  const materials = materialsList.materials;
  const windows = {
    A: "entre 9:00 y 13:00 hrs",
    B: "entre 14:00 y 19:00 hrs",
  };


  return (
    <Grid container spacing={2}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">Resumen del retiro</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center">
        <Grid item xs={10} align="center">
          <hr />
          <LinearStepper status={1} />
        </Grid>
        <Grid item container xs={12} sm={9}>
          <Grid item xs={12}>
            <hr />
            <Typography variant="body2" color="textSecondary">
              Fecha y hora
              </Typography>
            <Typography variant="body1">
              {formData.dateString + ", " + windows[formData.window]}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <hr />
            <Typography variant="body2" color="textSecondary">
              Descripción
              </Typography>
            <Typography variant="body1">{formData.description} </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <img
            src={formData.urlImage}
            style={{ maxWidth: "100%" }}
            alt="imagen del retiro"
          />
        </Grid>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Contenedores
            </Typography>
          <TableResume formData={formData} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Materiales
            </Typography>
          {formData.materialsExtra.map((material) => {
            return (
              <Chip
                avatar={
                  <FiberManualRecordIcon
                    style={{
                      color: materials[material.toLowerCase()].color,
                    }}
                  />
                }
                label={materials[material.toLowerCase()].name}
                className={classes.chip}
                key={"chip_" + material}
              />
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
    </Grid>
  );
}

function TableResume({ formData }) {
  const containers = [
    { type: "Pequeño", capacity: 80, key: "littleContainer" },
    { type: "Mediano", capacity: 120, key: "middleContainer" },
    { type: "Grande", capacity: 500, key: "bigContainer" },
    { type: "Gigante", capacity: 1000, key: "giantContainer" },
  ];

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Contenedor</strong>
          </TableCell>
          <TableCell>
            <strong>Cantidad</strong>
          </TableCell>
          <TableCell>
            <strong>Volumen aproximado</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {containers.map((container, i) => {
          if (formData.containers[container.key]) {
            return (
              <TableRow key={"container_" + i}>
                <TableCell>{container.type}</TableCell>
                <TableCell>{formData.containers[container.key]}</TableCell>
                <TableCell>
                  {formData.containers[container.key] * container.capacity}{" "}
                  Litros
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Total</strong>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <strong>
              {formData.containers.littleContainer * 80 +
                formData.containers.middleContainer * 120 +
                formData.containers.bigContainer * 500 +
                formData.containers.giantContainer * 1000}{" "}
              Litros
            </strong>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
}

const steps = [
  { name: "Solicitud", content: "Porcesando solicitud" },
  { name: "Transportista", content: "Buscando óptimo" },
  { name: "Recolección", content: "Ejecutando retiro" },
  { name: "Materiales", content: "Esperando reporte de reciclaje" },
];

function LinearStepper({ status }) {
  return (
    <Stepper activeStep={status} orientation="horizontal">
      {steps.map((step, i) => (
        <Step key={i}>
          <StepLabel>{step.name}</StepLabel>
          <StepContent>{step.content}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

LinearStepper.propTypes = {
  status: PropTypes.number.isRequired,
};

TableResume.propTypes = {
  formData: PropTypes.object.isRequired,
};

DialogBody.propTypes = {
  setViewState: PropTypes.func.isRequired,
  removal: PropTypes.object.isRequired,
};
