import React from "react";
import Materials from "../resources/materials";
const AppContext = React.createContext();

export function AppContextProvider(props) {
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [headerData, setHeaderData] = React.useState({
    title: "",
    subtitle: "",
    urlLogo: "",
  });
  const [notification, setNotification] = React.useState({
    open: false,
    message: "",
    title: "",
    severity: "success",
  });
  const helpers = Materials.materials;

  const value = React.useMemo(() => {
    return {
      userData,
      loading,
      headerData,
      setLoading,
      notification,
      setNotification,
      user,
      helpers,
    };
  }, []);

  return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("NO SUSCRITO AL CONTEXTO");
  }
  return context;
}

