import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import reportsActions from "../../../redux/actions/reports";

export default function ModalDelete() {
  const reports = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    local: "",
    dataRange: "",
  });
  useEffect(() => {
    if (reports.dataModal) {
      setData({
        ...data,
        local:
          reports.dataModal?.localID?.customerID?.brand +
          " " +
          reports.dataModal?.localID?.name,
        dateRange: reports.dataModal?.dateRange,
      });
    }
  }, [reports.dataModal]);

  const handleDelete = async () => {
    dispatch(reportsActions.deleteReport({ reportID: reports.dataModal._id }));
    handleClose();
  };

  const handleClose = async () => {
    dispatch(reportsActions.setDeleteModal(false));
    dispatch(reportsActions.setDataModal(null));
  };

  return (
    <Dialog
      open={reports.deleteModal}
      onClose={() => handleClose()}
      scroll={"body"}
    >
      <DialogContent>
        <Typography variant="h5">Eliminar reporte</Typography>
        <hr />
        <Typography variant="body1">
          ¿Seguro que quieres eliminar el reporte de {data.local} de{" "}
          {data.dateRange}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button color="secondary" onClick={() => handleClose()}>
            No
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Sí
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
