import React from "react";
import { Grid, useTheme } from "@material-ui/core";
import StateCard from "./StateCards";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";

function Cards() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const removals = useSelector((state) => state.removals);

  const [completes, setCompletes] = React.useState("...");
  const [incompletes, setIncompletes] = React.useState("...");
  const [suscription, setSuscription] = React.useState("...");
  const [extra, setExtra] = React.useState("...");
  const [sensor, setSensor] = React.useState("...");
  const [notifyToggle, setNotifyToggle] = React.useState(false);
  const [cont, setCont] = React.useState(0);

  React.useEffect(() => {
    if (removals.cards) {
      setCompletes(removals.cards.completes);
      setIncompletes(removals.cards.incompletes);
      setSuscription(removals.cards.suscription);
      setExtra(removals.cards.extra);
      setSensor(removals.cards.sensor);
      setCont(0);
    }
  }, [removals.cards]);

  React.useEffect(() => {
    let interval = null;
    if (cont < 8) {
      interval = setInterval(() => {
        setNotifyToggle(!notifyToggle);
        setCont(cont + 1);
      }, 300);
      return () => clearInterval(interval);
    } else {
      setNotifyToggle(true);
    }
  }, [notifyToggle, cont]);

  const setSelector = (value) => {
    dispatch(removalsActions.setSelector(value));
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={2} xs={6} onClick={() => setSelector("COMPLETE")}>
        <StateCard
          title="COMPLETOS"
          value={completes ? completes : "..."}
          background={
            removals.selector === "COMPLETE"
              ? theme.palette.success.main
              : theme.palette.primary.main
          }
        />
      </Grid>
      <Grid item sm={2} xs={6} onClick={() => setSelector("INCOMPLETE")}>
        <StateCard
          title="INCOMPLETOS"
          value={incompletes ? incompletes : "..."}
          background={
            removals.selector === "INCOMPLETE"
              ? theme.palette.success.main
              : theme.palette.primary.main
          }
        />
      </Grid>
      <Grid item sm={3} xs={6} onClick={() => setSelector("SUSCRIPTION")}>
        <StateCard
          title="SOLICITADOS SUSCRIPCIÓN"
          value={suscription ? suscription : "..."}
          background={
            removals.selector === "SUSCRIPTION"
              ? theme.palette.success.main
              : suscription && notifyToggle
              ? theme.palette.warning.main
              : theme.palette.primary.main
          }
        />
      </Grid>
      <Grid item sm={3} xs={6} onClick={() => setSelector("EXTRA")}>
        <StateCard
          title="SOLICITADOS PLATAFORMA EXTRA"
          value={extra ? extra : "..."}
          background={
            removals.selector === "EXTRA"
              ? theme.palette.success.main
              : extra && notifyToggle
              ? theme.palette.warning.main
              : theme.palette.primary.main
          }
        />
      </Grid>
      <Grid item sm={2} xs={6} onClick={() => setSelector("SENSOR")}>
        <StateCard
          title="SOLICITADOS SENSOR"
          value={sensor ? sensor : "..."}
          background={
            removals.selector === "SENSOR"
              ? theme.palette.success.main
              : sensor && notifyToggle
              ? theme.palette.warning.main
              : theme.palette.primary.main
          }
        />
      </Grid>
    </Grid>
  );
}

export default Cards;
