import React from "react";
import { Card, Grid, Typography, Switch } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PropTypes from "prop-types";

export default function Controls({ setViews, views }) {
  return (
    <Grid container>
      <Grid item>
        <Card style={{ padding: 6, maxWidth: 260 }}>
          <Typography component="h5" align="center">
            Mostrar
          </Typography>
          <hr />
          <Grid container>
            <Grid item xs={8} align="center">
              <Typography variant="body2" color="textSecondary" align="left">
                <LocationOnIcon
                  style={{
                    color: "#ff4444",
                  }}
                />
                Retirar urgente
              </Typography>
            </Grid>
            <Grid item xs={4} align="center">
              <Switch
                color="primary"
                checked={views.red}
                onChange={(e) => setViews({ ...views, red: e.target.checked })}
              />
            </Grid>
            <Grid item xs={8} align="center">
              <Typography variant="body2" color="textSecondary" align="left">
                <LocationOnIcon
                  style={{
                    color: "#ffbb33",
                  }}
                />
                Apto para retirar
              </Typography>
            </Grid>
            <Grid item xs={4} align="center">
              <Switch
                color="primary"
                checked={views.yellow}
                onChange={(e) =>
                  setViews({ ...views, yellow: e.target.checked })
                }
              />
            </Grid>
            <Grid item xs={8} align="center">
              <Typography variant="body2" color="textSecondary" align="left">
                <LocationOnIcon
                  style={{
                    color: "#6ecb4c",
                  }}
                />
                En llenado
              </Typography>
            </Grid>
            <Grid item xs={4} align="center">
              <Switch
                color="primary"
                checked={views.green}
                onChange={(e) => setViews({ ...views, green: e.target.checked })}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

Controls.propTypes = {
  views: PropTypes.object.isRequired,
  setViews: PropTypes.func.isRequired,
};
