import React, { useState, useEffect } from "react";
import Router from "../src/components/Router";
import NavBar from "./components/Navigation/NavBar";
import Menu from "./components/Navigation/Menu";
import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { AppContextProvider } from "./context/AppContext";
import "./index.css";
import { CssBaseline, useMediaQuery, useTheme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import utilsActions from "./redux/actions/utils";
import authActions from "./redux/actions/auth";
import appActions from "./redux/actions/app";

import Notifies from "./components/Notifies";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const token = window.localStorage.getItem("token");
if (token && token !== null) {
  axios.defaults.headers.common.authorization = token;
}

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => dispatch(appActions.setIsMobile({ isMobile })), [isMobile]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(authActions.verifyToken());
      if (auth.loged === "LOGED") {
        dispatch(utilsActions.fetchData());
      }
    };
    fetchData();
  }, [auth.loged]);

  return (
    <MuiThemeProvider theme={app.theme === "LIGHT" ? lightTheme : darkTheme}>
      <CssBaseline />
      <Notifies />
      <NavBar />
      <div style={{ marginTop: 60 }}>{!app.isMobile && <Menu />}</div>
      <main
        id="content"
        style={{
          padding: "2%",
        }}
      >
        <Router />
      </main>
    </MuiThemeProvider>
  );
}
export default () => (
  <AppContextProvider>
    <App />
  </AppContextProvider>
);
