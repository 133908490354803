import materials from "../../resources/materials";

const defaultState = {
  locals: [],
  transporters: [],
  customers: [],
  dispositions: [],
  materials: materials.materials,
  transporterlist: [],
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "UTILS_FETCH_LOCALS": {
      return { ...state, locals: payload };
    }
    case "UTILS_FETCH_TRANSPORTERS": {
      return { ...state, transporters: payload };
    }
    case "UTILS_FETCH_CUSTOMERS": {
      return { ...state, customers: payload };
    }
    case "UTILS_FETCH_DISPOSITIONS": {
      return { ...state, dispositions: payload };
    }
    case "UTILS_FETCH_TRANSPORTERLIST": {
      return { ...state, transporterlist: payload };
    }
    default:
      return state;
  }
}

export default reducer;
