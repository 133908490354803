const defaultState = {
    learning: []
  };

  function reducer(state = defaultState, { type, payload }) {
    switch (type) {
      case "LEARNING_FETCH": {
        return { ...state, learning: payload };
      }
      default:
        return state;
    }
  }
  export default reducer;