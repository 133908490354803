import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";

function parseNumber(number) {
  return Intl.NumberFormat("es-ES").format(Math.round(number * 1));
}

export default function EcoEq({ ecoeq, materials }) {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    var aux = {};
    ecoeq.map((eco) => {
      aux[eco.ID] = parseNumber(eco.q);
    });
    aux.MATERIALS = parseNumber(
      materials.reduce((ac, material) => ac + material.quantity * 1, 0)
    );
    setData({ ...aux });
  }, [ecoeq]);

  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Grid item xs={12} align="center">
            <h4>Eco equivalencias</h4>
            <br></br>
          </Grid>
          <Grid item align="center">
            <img
              src={
                "https://s3.amazonaws.com/accioncircular.com/resources/gota.png"
              }
              style={{ height: 60 }}
              alt="imagen ecoequivalencia"
            />
            <h4>{data.PETROL} L</h4>
            <h5>de petróleo</h5>
          </Grid>
          <Grid item align="center">
            <img
              src={
                "https://s3.amazonaws.com/accioncircular.com/resources/rayo.png"
              }
              style={{ height: 60 }}
              alt="imagen ecoequivalencia"
            />
            <h4>{data.ENERGY} kWatt</h4>
            <h5>de energía</h5>
          </Grid>
          <Grid item align="center">
            <img
              src={
                "https://s3.amazonaws.com/accioncircular.com/resources/fabrica.png"
              }
              style={{ height: 60 }}
              alt="imagen ecoequivalencia"
            />
            <h4>{data.CO2} Kg</h4>
            <h5>de C02</h5>
          </Grid>
          <Grid item align="center">
            <img
              src={
                "https://s3.amazonaws.com/accioncircular.com/resources/gota+agua.png"
              }
              style={{ height: 60 }}
              alt="imagen ecoequivalencia"
            />
            <h4>{data.WATER} L</h4>
            <h5>de agua</h5>
          </Grid>
          <Grid item align="center">
            <img
              src={
                "https://s3.amazonaws.com/accioncircular.com/resources/arbol.png"
              }
              style={{ height: 60 }}
              alt="imagen ecoequivalencia"
            />
            <h4>{data.TREE} Unidades</h4>
            <h5>de árboles</h5>
          </Grid>
          <Grid item align="center">
            <img
              src={
                "https://s3.amazonaws.com/accioncircular.com/resources/balanza.png"
              }
              style={{ height: 60 }}
              alt="imagen ecoequivalencia"
            />
            <h4>{data.MATERIALS}Kg</h4>
            <h5>de material</h5>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
