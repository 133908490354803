import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../redux/actions/removals";

export default function ModalDelete() {
  const dispatch = useDispatch();
  const removals = useSelector((state) => state.removals);
  const [name, setName] = React.useState(null);
  const [datetimeRequest, setDatetimeRequest] = useState(null);

  useEffect(() => {
    if (removals.dataModal) {
      setName(removals.dataModal.localID.name);
      setDatetimeRequest(removals.dataModal.datetimeRequest);
    }
  }, [removals.dataModal]);

  const handleDeleteRemoval = async () => {
    await dispatch(
      removalsActions.deleteRemoval({ removalID: removals.dataModal?._id })
    );
    await dispatch(removalsActions.setDataModal(null));
    await dispatch(removalsActions.setDeleteModal(false));
  };

  const handleClose = async () => {
    await dispatch(removalsActions.setDataModal(null));
    await dispatch(removalsActions.setDeleteModal(false));
  };

  return (
    <Dialog open={true} onClose={() => handleClose()} scroll={"body"}>
      <DialogContent>
        <Typography variant="h5">Eliminar retiro</Typography>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              ¿Seguro que quieres eliminar el retiro?
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Empresa/Local:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Fecha de solicitud: </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {new Intl.DateTimeFormat("es-CL", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(new Date(datetimeRequest))}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small">
          <Button
            color="secondary"
            onClick={() => handleClose()}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={() => handleDeleteRemoval()}
            variant="contained"
          >
            Aceptar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
