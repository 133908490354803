import React, { useEffect, useState } from "react";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Button,
  ButtonGroup,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText, TextField, MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import StateCards from "./StateCards";
import RemovalList from "./RemovalList";
import ModalCreate from "./ModalCreate";
import SearchBar from "./SearchBar";

import ModalDelete from "./ModalDelete";
import ModalExtra from "./ModalExtra";

import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../redux/actions/removals";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const removals = useSelector((state) => state.removals);

  const [view, setView] = useState(false);
  const [excel, setExcel] = useState(null);
  const [error, setError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const utils = useSelector((state) => state.utils);
  const [dato, setDato] = useState({localID: "EMPTY"});
  const [status, setStatus] = useState(0);
  
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    const start = async () => {
      const data = {
        type: "DATETIME_REQUEST",
        firstParam: {
          datetimeRequest: { $gte: "2024-03-04", $lte: "2024-04-05" },
        },
      };
      //dispatch(removalsActions.setSearch(data));
      dispatch(removalsActions.fetchRemovals(page));
      dispatch(removalsActions.removalsCount());
    };
    start();
  }, []);

  const goSinader = () => {
    console.log("sadasdsad");
  };

  const handleChange = (e) => {
    setExcel(e.target.files[0]);
  };

  async function handleUpload(e) {
    
    e.preventDefault();
    setIsUploading(true);
    const formData = new FormData();
    
    formData.append("excelDocument", excel);
    
    
      try {
      setStatus(4);
      const response = await dispatch(removalsActions.uploadExcel(formData));
      setIsUploaded(true);
      setIsUploading(false);
      window.location.reload();
    } catch (err) {
      setError(err.message);
      setIsUploading(false);
      handleClose();
    }
}

  const handleClose = () => {
    setStatus(0);
    setView(false);
  };

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
           
            <Grid item>
              {
              removals.selector === "COMPLETE" ? (
                <Typography variant="h4">Retiros completos</Typography>
              ) : removals.selector === "INCOMPLETE" ? (
                <Typography variant="h4">Retiros incompletos</Typography>
              ) : removals.selector === "SUSCRIPTION" ? (
                <Typography variant="h4">Retiros por suscripción</Typography>
              ) : removals.selector === "EXTRA" ? (
                <Typography variant="h4">Retiros extra</Typography>
              ) : removals.selector === "SENSOR" ? (
                <Typography variant="h4">Retiros por sensor</Typography>
              ) : (
                <div />
              )}
            </Grid>
            <Grid item>
              <ButtonGroup>
              {/* <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  style={{marginRight: 10,}}
                  onClick={() => goSinader()}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  DESCARGAR SINADER
                </Button> */}

                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => setView(true)}
                >
                  Excel
                </Button>

                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => dispatch(removalsActions.setCreateModal(true))}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  AGREGAR RETIRO MANUAL
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <hr></hr>
              <StateCards />
              <hr></hr>
            </Grid>
            <Grid item xs={12}>
              {removals.createModal && <ModalCreate />}
              {removals.deleteModal && <ModalDelete />}
              {removals.viewExtraModal && <ModalExtra />}

              <SearchBar />
              <RemovalList  page={page}  setPage={setPage}/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog
            open={view}
            aria-labelledby="form-dialog-title"
            scroll="body"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">Ingresar archivo excel</DialogTitle>
            <form onSubmit={handleUpload}>
            <DialogContent>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
          
          </Grid>
          <Grid item xs={12} sm={12}>
              <TextField
              name="excelDocument"
              type="file" 
              onChange={handleChange}
              variant="outlined"
              color="primary"
              fullWidth
              />
            </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button type="submit">Enviar</Button>
            </DialogActions>
            </form>
          </Dialog>

    </Paper>
  );
}

export default Index;
