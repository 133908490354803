import React from "react";
// import { MDBBtn, MDBBtnGroup, MDBBadge } from "mdbreact";
import { Grid, Card, CardContent, Paper } from "@material-ui/core";
import { getStatsRemoval, getValors } from "../../../api/dashboardApi";
import { makeStyles } from "@material-ui/core/styles";
import Cards from "./Cards";
import Charts from "./Charts";
import NumberFormat from "react-number-format";
import Dates from "./Dates";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
export default function ModalDelete() {
  const classes = useStyles();
  // const [status, setStatus] = React.useState(null)
  const dateNow = new Date();
  const [dateInit, setDateInit] = React.useState(
    new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)
  );
  const [dateFinish, setDateFinish] = React.useState(
    new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0, 23, 59, 59)
  );
  const [data, setData] = React.useState(null);
  const [dolar, setDolar] = React.useState(730);
  const [uf, setUf] = React.useState(28300);

  React.useEffect(() => {
    // getValors().then((response) => {
    //   setDolar(response.dolar.valor);
    //   setUf(response.uf.valor);
    // });
    // getStatsRemoval({ dateInit, dateFinish }).then((response) => {
    //   setData(response);
    // });
  }, [dateInit, dateFinish]);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Dates
            setDateInit={setDateInit}
            setDateFinish={setDateFinish}
            dateInit={dateInit}
            dateFinish={dateFinish}
          />
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h5 style={{ textAlign: "center" }}>
                UF{" "}
                <NumberFormat
                  value={Math.round(uf, 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />{" "}
                CLP
              </h5>
            </Grid>
            <Grid item xs={6}>
              <h5 style={{ textAlign: "center" }}>
                Dolar{" "}
                <NumberFormat
                  value={Math.round(dolar, 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />{" "}
                CLP
              </h5>
            </Grid>
          </Grid>
          <hr />
          <Cards data={data} uf={uf} />
          <hr />
          <Charts data={data} uf={uf} />
          <hr />
        </CardContent> 
        {/* <h4>Temporalmente deshabilitado</h4> */}
      </Card>
    </Paper>
  );
}
