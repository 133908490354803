import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import validator from "email-validator";
import { format } from "rut.js";
import General from "./General";
import Vehicles from "./Vehicles";

// import ResidueSelect from "../../resources/UI/ResidueSelect";
import regProvComsData from "../../../../resources/regProvCom";

import transportersActions from "../../../../redux/actions/transporters";

const initState = {
  company: "",
  rutEnterprise: "",
  name: "",
  lastName: "",
  phone: "",
  email: "",
  address: "",
  commune: "",
  region: "",
  province: "",
  certification: "",
  // categories: [],
  // days: [],
  // timeWindows: [],
  // communes: [],
  _id: null,
};

const initRegProvCom = {
  name: "",
  code: "",
  regCode: "",
  provCode: "",
}

const EditModal = ({ setOpenEdit, editData }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initState); //formData transporter
  const [vehiclesFormData, setVehiclesFormData] = useState([]); // formData vehicles add 
  const [formDataVehicleSelected, setFormDataVehicleSelected] = useState({}); // formData edit vehicle selected

  const [fileValue, setFileValue] = useState("");
  const [pdfError, setPdfError] = useState(false);

  const [communes] = useState([]);
  const [communeSelected, setCommuneSelected] = useState(initRegProvCom);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  // REGIONES PROVINCIAS Y COMUNAS
  useEffect(() => {
    regProvComsData.forEach(regionsElements => {
      regionsElements.provincias.forEach(provinceElement => {
        provinceElement.comunas.forEach(communeElement => {
          communes.push({
            name: communeElement.name,
            code: communeElement.code,
            regCode: regionsElements.region_iso_3166_2,
            provCode: provinceElement.name,
          })
        })
      })
    })
    communes.sort((x, y) => {
      if (x.name > y.name) {
        return 1;
      }
      if (x.name < y.name) {
        return -1;
      }
      return 0;
    })
  }, [])

  useEffect(() => {
    if (editData) {
      const parsedCommune = communes.find((commmune) => commmune.code === editData.commune);
      setCommuneSelected(parsedCommune);

      setFormData({ ...formData, ...editData });
      setVehiclesFormData(editData.vehiclesID);
    }
  }, [editData]);

  const updateData = (data, name) => {
    setFormData({ ...formData, [name]: data });
  };

  const handleCloseModal = () => {
    setOpenEdit(false);
  };

  const success = () => {
	 // console.log(format(formData.rutEnterprise));
	  formData.rutEnterprise=format(formData.rutEnterprise);
	  formData.rut=format(formData.rut);
    if (
      formData.name &&
      formData.lastName &&
      formData.rut &&
      formData.phone &&
      formData.address &&
      formData.commune &&
      formData.company &&
      formData.rutEnterprise &&
      validator.validate(formData.email)
    ) {
      dispatch(transportersActions.updateTransporter({
        transporter: formData,
        vehicles: vehiclesFormData,
        file: formData.file,
        vehicle: formDataVehicleSelected
      }));
      handleCloseModal();
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={true && editData !== null} onClose={handleCloseModal} maxWidth="xl">
      <DialogContent>
        <Grid item>
          <div>
            <h4>Editar transportista</h4>
          </div>
        </Grid>
        <General
          formData={formData}
          setFormData={setFormData}
          updateData={updateData}
          communes={communes}
          communeSelected={communeSelected}
          setCommuneSelected={setCommuneSelected}
          error={error}
          fileValue={fileValue}
          setFileValue={setFileValue}
          pdfError={pdfError}
          setPdfError={setPdfError}
        />
        <Vehicles
          vehiclesFormData={vehiclesFormData}
          setVehiclesFormData={setVehiclesFormData}
          error={error}
          setError={setError}
          formDataVehicleSelected={formDataVehicleSelected}
          setFormDataVehicleSelected={setFormDataVehicleSelected}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => success()} >
            Actualizar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

EditModal.propTypes = {
  open: PropTypes.bool,
};

export default EditModal;
