import React from "react";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModalCreateCustomer from "./ModalCreateCustomer";
import ModalCreateLocal from "./ModalCreateLocal";
import ModalEditCustomer from "./ModalEditCustomer";
import ModalEditLocal from "./ModalEditLocal";
import ModalDelete from "./ModalDelete";
import CustomerList from "./CustomerList";
import LocalList from "./LocalList";
import ViewCustomer from "./ViewCustomer";
import StateCards from "./StateCards";
import {
  useCustomersContext,
  CustomersContextProvider,
} from "../../../context/CustomersContext";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Index() {
  const classes = useStyles();
  const {
    viewModalCreateCustomer,
    setViewModalCreateCustomer,
    viewModalCreateLocal,
    viewModalEditLocal,
    viewModalEditCustomer,
    viewCustomer,
    viewDelete,
    setViewModalCreateLocal,
    select,
  } = useCustomersContext();

  return (
    <Paper className={classes.root}>
      {viewModalCreateCustomer && <ModalCreateCustomer />}
      {viewModalCreateLocal && <ModalCreateLocal />}
      {viewModalEditCustomer && <ModalEditCustomer />}
      {viewDelete && <ModalDelete />}
      {viewModalEditLocal && <ModalEditLocal />}
      {viewCustomer && <ViewCustomer />}
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">
                {select === "CUSTOMER" ? "Clientes" : "Locales"}
              </Typography>
            </Grid>
            <Grid item>
              {select === "CUSTOMER" ? (
                <Button
                  onClick={() => setViewModalCreateCustomer(true)}
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Registrar cliente
                </Button>
              ) : (
                <Button
                  onClick={() => setViewModalCreateLocal(true)}
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Registrar sede / sucursal
                </Button>
              )}
            </Grid>
          </Grid>
          <hr></hr>
          <StateCards />
          <br />
          {select === "CUSTOMER" ? <CustomerList /> : <LocalList />}
        </CardContent>
      </Card>
    </Paper>
  );
}

export default () => (
  <CustomersContextProvider>
    <Index />
  </CustomersContextProvider>
);
