import React from "react";
import Axios from "../api/Axios";
import { useAppContext } from "./AppContext";
const SensorsContext = React.createContext();
const apiUrl = process.env.REACT_APP_API_URL;

export function SensorsContextProvider(props) {
    const [sensors, setSensors] = React.useState([]);
    const [locals, setLocals] = React.useState([]);
    const { helpers } = useAppContext();
    React.useEffect(() => {
        getSensors().then((response) => {
            setSensors(response.data);
        });
        getLocals().then((response) => {
            setLocals(response.data);
        });
    }, []);
    const value = React.useMemo(() => {
        return {
            sensors,
            locals,
            getSensorsTable,
            getSensorsFilled,
            getSensorsLocation,
            putSensorState,
            helpers,
        };
    }, [sensors,locals]);
    return <SensorsContext.Provider value={value} {...props} />;
}
const getSensors = async () => {
    return await Axios.get(apiUrl + "/adminTool/sensors", {
        headers: {
            authorization: window.localStorage.getItem("token"),
        },
    })
};
const getLocals = async () => {
    return await Axios.get(apiUrl + "/adminTool/locals", {
        headers: {
            authorization: window.localStorage.getItem("token"),
        },
    })
};
export function useSensorsContext() {
    const context = React.useContext(SensorsContext);
    if (!context) {
        throw new Error("No suscrito");
    }
    return context;
}

const getSensorsTable = () => {
    return new Promise((resolve, reject) => {
        getSensors().then((response) => {
            resolve(response.data);
        });
    });
};

const getSensorsFilled = () => {
    return new Promise((resolve, reject) => {
        Axios.get(apiUrl + "/adminTool/sensors/filled", {
            headers: {
                authorization: window.localStorage.getItem("token"),
            },
        }).then((response) => {
            resolve(response.data);
        });
    });
};

const getSensorsLocation = () => {
    return new Promise((resolve, reject) => {
        Axios.get(apiUrl + "/adminTool/sensors/location", {
            headers: {
                authorization: window.localStorage.getItem("token"),
            },
        }).then((response) => {
            resolve(response.data);
        });
    });
};

const putSensorState = ({sensorID}) => {
    return new Promise((resolve, reject) => {
        Axios.get(apiUrl + "/adminTool/sensors/stateSensorByID", {
            headers: {
                authorization: window.localStorage.getItem("token"),
            },
            params:{
              _id:sensorID,
            },
        }).then((response) => {
            resolve(response.data);
        });
    });
};

