import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import functions from "../../../../resources/functions";

// const initState = {
//   licensePlate: "",
//   brand: "",
//   model: "",
//   type: "",
//   description: "",
//   maxVolume: 0,
//   resolutions: [],
//   // resolutionsURL: [],
// }

export default function EditVehicle({
  vehiclesFormData,
  isVehicleSelected,
  setIsVehicleSelected,
  vehicleSelectedData,
  resolutions,
  setStatus,
  formDataVehicleSelected,
  setFormDataVehicleSelected,
}) {

  const [autocompleteResolutions, setAutocompleteResolutions] = useState([]);

  const style = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "text",
    InputLabelProps: {
      shrink: true,
    },
  };
  
  const style2 = {
    fullWidth: true,
    margin: "dense",
    variant: "outlined",
    type: "number",
    InputLabelProps: {
      shrink: true,
    },
  };

  useEffect(() => {
    setFormDataVehicleSelected(vehicleSelectedData);
    if (vehicleSelectedData.resolutions) {
      vehicleSelectedData.resolutions.map(resolutionElement => {
        setAutocompleteResolutions(
          ...autocompleteResolutions,
          resolutions.filter((resolution) => resolution.code === resolutionElement))
      });
    }
  }, [vehicleSelectedData]);

  useEffect(() => {
    if (
      // formDataVehicleSelected.brand === "" ||
      // !formDataVehicleSelected.model ||
      // formDataVehicleSelected.resolution === "" ||
      formDataVehicleSelected.licensePlate === ""
    ) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  }, [formDataVehicleSelected]);

  const updateData = (data, name) => {
    setFormDataVehicleSelected({ ...formDataVehicleSelected, [name]: data });
  };

  const handleChangeVehicle = (event) => {
    setIsVehicleSelected(event.target.value)
  };

  const formatLicensePlate = (licensePlate) => {
    return functions.formatLicensePlate(licensePlate);
  };

  const handleResolutions = (k) => {
    setAutocompleteResolutions(k);
    setFormDataVehicleSelected({
      ...formDataVehicleSelected, resolutions: k.map(element => {
        return element.code
      })
    });
  };

  function Item(params) {
    return (
      <div>
        <TextField
          {...style}
          {...params}
          label="Resoluciones"
        // error={error && !vehiclesFormData.resolutions}
        />
      </div>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          {vehiclesFormData.length > 0 &&
            <Grid item xs={12}>
              <TextField
                {...style}
                select
                label="Selecciona un vehículo a editar"
                value={isVehicleSelected}
                onChange={handleChangeVehicle}
              >
                {vehiclesFormData.map((vehicleElement, i) => (
                  <MenuItem key={`vehicle_${i}`} value={vehicleElement._id}>{vehicleElement.licensePlate}</MenuItem>
                ))}
              </TextField>
            </Grid>
          }
          {isVehicleSelected &&
            <React.Fragment>
              <Grid item xs={6}>
                <TextField
                  {...style}
                  label="Marca"
                  value={formDataVehicleSelected.brand}
                  onChange={(event) => updateData(event.target.value, "brand")}
                // error={error && !vehicleSelectedData.brand}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...style}
                  label="Modelo"
                  value={formDataVehicleSelected.model}
                  onChange={(event) => updateData(event.target.value, "model")}
                // error={error && !vehicleSelectedData.model}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...style}
                  label="Placa patente"
                  value={formatLicensePlate(formDataVehicleSelected.licensePlate)}
                  onChange={(event) => updateData(event.target.value, "licensePlate")}
                // error={error && !vehicleSelectedData.licensePlate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...style}
                  label="Tipo"
                  value={formDataVehicleSelected.type}
                  onChange={(event) => updateData(event.target.value, "type")}
                // error={error && !vehicleSelectedData.type}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...style2}
				  
                  label="Máximo Volumen"
                  value={formDataVehicleSelected.maxVolume}
                  onChange={(event) => updateData(event.target.value, "maxVolume")}
                // error={error && !vehicleSelectedData.maxVolume}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  {...style}
                  label="Unidades"
                  value={formDataVehicleSelected.typeUnit}
                  onChange={(event) => updateData(event.target.value, "typeUnit")}
                // error={error && !vehicleSelectedData.maxVolume}
                name="type"
                select
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
                >
                  <MenuItem value="litros">Litros</MenuItem>
                  <MenuItem value="m3">M3</MenuItem>
                  <MenuItem value="toneladas">Toneladas</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                {/* {autocompleteResolutions.length && */}
                  <Autocomplete
                    multiple
                    id="resolutions-select"
                    options={resolutions}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={autocompleteResolutions}
                    renderInput={(params) => Item(params)}
                    onChange={(_, k) => handleResolutions(k)}
                  />
                {/* } */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...style}
                  label="Descripción"
                  multiline
                  rows={4}
                  value={formDataVehicleSelected.description}
                  onChange={(event) => updateData(event.target.value, "description")}
                // error={error && !vehicleSelectedData.description}
                />
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </Grid>
    </div>
  );
}


