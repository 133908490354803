import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import General from "./General";
import removalsActions from "../../../../redux/actions/removals";


export default function ModalExtra() {
  const dispatch = useDispatch();

  const removals = useSelector((state) => state.removals);

  const [data, setData] = useState({});

  useEffect(() => {
    if (removals.dataExtraModal) {
      setData(removals.dataExtraModal)
    }
  }, [removals.dataExtraModal]);

  const handleClose = () => {
    dispatch(removalsActions.setDataExtraModal(null));
    dispatch(removalsActions.setViewExtraModal(false));
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll={"body"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogContent>
        <General data={data} />
      </DialogContent>
    </Dialog>
  );
}

