import React, { useEffect, useState, Fragment } from "react";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Typography, ButtonGroup, Button, DialogActions,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import SearchIcon from "@material-ui/icons/Search";
import Axios from "axios";
import swal from 'sweetalert';

// import { useAppContext } from "../../../../context/AppContext";
// import { useSensorsContext } from "../../../../context/SensorsContext";
import actionsSensors from "./../../../../redux/actions/sensors";
import SwitchButton from "./Switch";

import notifiesActions from "./../../../../redux/actions/notifies"

const style = {
  margin: "dense",
  variant: "outlined",
  InputLabelProps: {
    shrink: true,
  },
};
const materials = [
  /* {
    name: "Plásticos",
    value: "plastics",
  }, */
  /* {
    name: "Celulosa",
    value: "cel",
  }, */
  /* {
    name: "Orgánicos",
    value: "organics",
  }, */
  {
    name: "Aluminio",
    value: "aluminium",
  },
  {
    name: "Tetra Pak",
    value: "tetrapak",
  },
  {
    name: "Otros Metales",
    value: "metals",
  },
  {
    name: "Electrónicos",
    value: "electronics",
  },
  {
    name: "Textil",
    value: "textils",
  },
  {
    name: "Vidrios",
    value: "glass",
  },
  {
    name: "Madera",
    value: "wood",
  },
  {
    name: "Papel",
    value: "paper",
  },
  {
    name: "Cartón",
    value: "paperboard",
  },
  {
    name: "Pet",
    value: "pet",
  },
  {
    name: "Film",
    value: "film",
  },
  {
    name: "Aceite orgánicos",
    value: "oil",
  },
  {
    name: "Aceite lubricante usado",
    value: "oilu",
  },
  {
    name: "Otros",
    value: "others",
  },

];

const timeWindowsConstant = [
  {
    nameT: "A: 9:00 a 13:00",
    value: "A",
  },
  {
    nameT: "B: 14:00 a 19:00",
    value: "B",
  },
];

const dayWindowsConstant = [
  {
    nameD: "Lunes",
    value: "LUN",
  },
  {
    nameD: "Martes",
    value: "MAR",
  },
  {
    nameD: "Miércoles",
    value: "MIE",
  },
  {
    nameD: "Jueves",
    value: "JUE",
  },
  {
    nameD: "Viernes",
    value: "VIE",
  },
  {
    nameD: "Sábado",
    value: "SAB",
  },
];
export default function ViewSensors() {
  const [timeWindows, setTimeWindows] = React.useState([]);
  const [dayWindows, setDayWindows] = React.useState([]);
  const [general, setGeneral] = React.useState({
    maxRemovalsMonthly: 30,
    maxRemovalsDaily: 1,
    emailConfirm: true,
  });
  let [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const utils = useSelector((state) => state.utils);
  const sensors = useSelector((state) => state.sensors);

  React.useEffect(() => {
    if (sensors.localID !== "EMPTY" && sensors.customerID === "EMPTY" && sensors.model === "EMPTY") {
      dispatch(actionsSensors.sensorsConfigureByLocal({ localID: sensors.localID }));
    }
  }, [sensors.localID]);

  const handleChangeLocal = (e) => {
    if (e === "" || e === 0 || e === null || e === undefined) {
      //Control de excepciones
    } else {
      dispatch(actionsSensors.setLocalID({ localID: e._id }))
    }
  }
  const handleSuccess = () => {
    Axios.put(
      "/adminTool/sensors/putSensor",
      { data },
      {
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      console.log("logrado bro");
      dispatch(notifiesActions.enqueueNotify("SENSOR_CREATE_SUCCESS"));
    });
  };
  const handleChange = async (name, value, i) => {
    if (name === "containerHeight") {
      if (value < 0) {
        value = 0;
      } else if (value > 200) {
        value = 200;
      }
    }
    data = sensors.configuration;
    let aux = data;
    aux[i] = { ...data[i], [name]: value };
    setData([...aux]);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            id="cmbSucursal"
            options={utils.locals}
            freeSolo
            getOptionLabel={(option) => option.customerID.brand + " / " + option.name}
            onSelect={(event, newValue) => {
              handleChangeLocal(newValue);
            }}
            onChange={(event, newValue) => {
              handleChangeLocal(newValue);
            }}
            renderInput={(params) =>
              <TextField {...params}
                margin="dense"
                fullWidth
                label="Seleccione una sucursal"
                variant="outlined"
              />
            }
          />

        </Grid>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr></hr>
      </Grid>
      <br />
      {(sensors.localID !== "EMPTY") && (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              {...style}
              label="Máximo de retiros mensuales"
              value={general.maxRemovalsMonthly}
              onChange={(e) =>
                e.target.value >= 0
                  ? setGeneral({
                    ...general,
                    maxRemovalsMonthly: e.target.value,
                  })
                  : null
              }
              helperText="0 para retiros ilimitados"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              {...style}
              label="Máximo de retiros diarios"
              value={general.maxRemovalsDaily}
              onChange={(e) =>
                e.target.value >= 0
                  ? setGeneral({
                    ...general,
                    maxRemovalsDaily: e.target.value,
                  })
                  : null
              }
              helperText="0 para retiros ilimitados"
            />
          </Grid>
          <Grid item xs={3} align="center">
            <div
              style={{
                borderRadius: 4,
                padding: 6,
                border: `solid 1px #e0e0e0`,
              }}
            >
              <Typography variant="body2" color="textSecondary" align="justify">
                Enviar correo de confirmación antes de solicitar un retiro
              </Typography>

              <SwitchButton
                alert={false}
                value={general.emailConfirm}
                onChange={(e) =>
                  setGeneral({ ...general, emailConfirm: e.target.checked })
                }
                label={general.emailConfirm ? "Sí" : "No"}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={dayWindowsConstant}
              getOptionLabel={(option) => option.nameD}
              value={dayWindows}
              filterSelectedOptions
              onChange={(_, k) => setDayWindows([...k])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...style}
                  label="Día para solicitud de retiros"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={timeWindowsConstant}
              getOptionLabel={(option) => option.nameT}
              value={timeWindows}
              filterSelectedOptions
              onChange={(_, k) => setTimeWindows([...k])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...style}
                  label="Hora para solicitud de retiros"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Panel de configuración de solicitud de retiros de sensores
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>IMEI</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Altura del contenedor</TableCell>
                  <TableCell>Límite de llenado</TableCell>
                  <TableCell>Esperar a los demás sensores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sensors.configuration.map((sensor, i) => (
                  <TableRow key={"fila_" + i}>
                    <TableCell
                      value={sensor.imei}
                      defaultValue={sensor.imei}>{sensor.imei}</TableCell>
                    <TableCell>
                      <Select
                        margin="dense"
                        variant="outlined"
                        value={sensor.material}
                        defaultValue={sensor.material}
                        onChange={(e) =>
                          handleChange("material", e.target.value, i)
                        }
                      >
                        {Object.keys(utils.materials).map((material) => (
                          <MenuItem
                            key={"material_" + material}
                            value={material.toUpperCase()}
                          >
                            {utils.materials[material].name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        {...style}
                        InputProps={{
                          endAdornment: <InputAdornment>cm</InputAdornment>,
                        }}
                        value={sensor.containerHeight}
                        type="number"
                        onChange={(e) =>
                          handleChange("containerHeight", e.target.value, i)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        margin="dense"
                        variant="outlined"
                        value={sensor.fillLimitPercentage}
                        onChange={(e) =>
                          handleChange("fillLimitPercentage", e.target.value, i)
                        }
                      >
                        <MenuItem value={50}>50%</MenuItem>
                        <MenuItem value={60}>60%</MenuItem>
                        <MenuItem value={70}>70%</MenuItem>
                        <MenuItem value={80}>80%</MenuItem>
                        <MenuItem value={90}>90%</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <SwitchButton
                        value={sensor.waitOthersSensors}
                        onChange={(e) =>
                          handleChange("waitOthersSensors", e.target.checked, i)
                        }
                        label={
                          sensor.waitOthersSensors ? "Esperar" : "No esperar"
                        }
                        alert={true}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup size="small" variant="contained">
              <Button color="secondary" onClick={() => swal({
                title: "Acepta para guardar cambios",
                buttons: { Aceptar: { value: "funciona" } },
              }).then(value => {
                if (value)
                  handleSuccess()
              })}>
                Guardar
                </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
    </>
  );
}
