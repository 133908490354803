import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ModalCreate({ locals, deleteLocal, customers, suscriptions }) {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(locals);
  }, [locals, suscriptions,customers]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Cliente</TableCell>
          <TableCell>Nombre local</TableCell>
          <TableCell>Dirección</TableCell>
          <TableCell>Suscripción</TableCell>
          <TableCell>Contacto</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Teléfono</TableCell>
          <TableCell>Opciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((element, i) => (
          <TableRow key={i}>
            <TableCell>
              {
                customers.filter(
                  (el) => el._id === element.customerID
                )[0].brand
              }
            </TableCell>
            <TableCell>{element.name}</TableCell>
            <TableCell>{element.address}</TableCell>
            <TableCell>
              {
                suscriptions.filter(
                  (el) => el._id === element.suscriptionID
                )[0].name
              }
            </TableCell>
            <TableCell>{element.contactName}</TableCell>
            <TableCell>{element.email}</TableCell>
            <TableCell>{element.phone}</TableCell>
            <TableCell>
              <Button
                color="primary"
                size="small"
                onClick={() => deleteLocal(i)}
              >
                <DeleteIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
