import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";
import EcoIcon from "@material-ui/icons/Eco";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import WarningIcon from "@material-ui/icons/Warning";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import InfoIcon from "@material-ui/icons/Info";
import { Card, CardContent } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Empresa / local",
  },
  {
    id: "datetimeRequest",
    numeric: false,
    disablePadding: false,
    label: "Fecha de solicitud",
  },
  {
    id: "transporter",
    numeric: false,
    disablePadding: false,
    label: "Transportista",
  },
  {
    id: "datetimeRemoval",
    numeric: false,
    disablePadding: false,
    label: "Fecha de retiro",
  },
  { id: "payment", numeric: true, disablePadding: false, label: "Pago CLP" },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "data",
    numeric: false,
    disablePadding: false,
    label: "Datos",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({ removals }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("datetimeRequest");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (removals) {
      setRows(removals);
    }
  }, [removals]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Card>
      <CardContent>
        <h4 style={{ textAlign: "center" }}>Lista de retiros</h4>
        <br></br>
        <div className={classes.root}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(index);

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <StyledTableCell align="left">
                          {row.localID.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          }).format(new Date(row.datetimeRequest))}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.status === "PENDING_TRANS"
                            ? "-"
                            : row.transporterID.name +
                              " " +
                              row.transporterID.lastName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.status === "PENDING_PAYMENT" ||
                          row.status === "COMPLETE"
                            ? new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }).format(new Date(row.datetimeRemoval))
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.status === "COMPLETE" ? (
                            <NumberFormat
                              value={Math.round(row.payment, 0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.transporterID &&
                          row.payment !== 0 &&
                          row.urlReport &&
                          row.materials.reduce(
                            (sum, act) => sum + act.quantity,
                            0
                          ) ? (
                            <CheckBoxIcon style={{ color: "00C851" }} />
                          ) : (
                            <WarningIcon style={{ color: "#ffbb33" }} />
                          )}{" "}
                          <a
                            title={
                              row.lastModificationID
                                ? "Última modificación por " +
                                  row.lastModificationID.name +
                                  " el " +
                                  row.datetimeLastModification
                                : "No hay información"
                            }
                          >
                            <InfoIcon style={{ color: "#33b5e5" }} />
                          </a>
                        </StyledTableCell>

                        <StyledTableCell align="right">
                          {row.transporterID ? (
                            <a>
                              <LocalShippingIcon style={{ color: "00C851" }} />
                            </a>
                          ) : (
                            <LocalShippingIcon style={{ color: "#BDBDBD" }} />
                          )}{" "}
                          {row.payment !== 0 ? (
                            <a title={row.payment}>
                              <AttachMoneyIcon style={{ color: "00C851" }} />
                            </a>
                          ) : (
                            <AttachMoneyIcon style={{ color: "#BDBDBD" }} />
                          )}{" "}
                          {row.urlReport ? (
                            <a title="Descargar reporte">
                              <AssignmentTurnedInIcon
                                style={{ color: "00C851" }}
                                onClick={() => window.open(row.urlReport)}
                              />
                            </a>
                          ) : (
                            <AssignmentTurnedInIcon
                              style={{ color: "#BDBDBD" }}
                            />
                          )}{" "}
                          {row.materials.reduce(
                            (sum, act) => sum + act.quantity,
                            0
                          ) ? (
                            <a title="Materiales agregados">
                              <EcoIcon style={{ color: "00C851" }} />
                            </a>
                          ) : (
                            <EcoIcon style={{ color: "#BDBDBD" }} />
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {rows.length ? (
              ""
            ) : (
              <h4 style={{ margin: 24 }}>No se encontraron resultados</h4>
            )}
          </TableContainer>
          <TablePagination
            labelRowsPerPage={"Filas por página"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${
                count !== -1 ? count : `más de ${to}`
              }`
            }
            rowsPerPageOptions={[7, 14, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </CardContent>
    </Card>
  );
}
