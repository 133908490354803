import axios from "axios";
import notifiesActions from "./notifies";

const fetchDispositions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/adminTool/dispositions");
      dispatch({ type: "DISPOSITIONS_FETCH", payload: response.data });
      return true;
    } catch (e) {
      return false;
    }
  };
};

const fetchDispositionsLocationsByRegions = (region) => {
  return async (dispatch) => {
    try {
      let response = await axios.get("/adminTool/dispositions/dispositionsLocationByRegion", {
        params: { region },
      });
      dispatch({ type: "DISPOSITIONS_FETCH_LOCATION_BY_REGION_SUCCESS", payload: response.data });
    } catch (e) {
      console.log(e);
    }
  };
};

const postDisposition = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/adminTool/dispositions", payload);
      dispatch(notifiesActions.enqueueNotify("DISPOSITION_CREATE_SUCCESS"));
      dispatch(fetchDispositions());
      return response.data;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("DISPOSITION_CREATE_FAIL"));
      return false;
    }
  };
};

const updateDisposition = (payload) => {
  return async (dispatch) => {
    try {
      await axios.patch("/adminTool/dispositions", payload);
      dispatch(notifiesActions.enqueueNotify("DISPOSITION_UPDATE_SUCCESS"));
      dispatch(fetchDispositions());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("DISPOSITION_UPDATE_FAIL"));
      return false;
    }
  };
};

const deleteDisposition = ({ dispositionID }) => {
  console.log(dispositionID);
  return async (dispatch) => {
    try {
      await axios.delete("/adminTool/dispositions?dispositionID=" + dispositionID);
      dispatch(notifiesActions.enqueueNotify("DISPOSITION_DELETE_SUCCESS"));
      dispatch(fetchDispositions());
      return true;
    } catch (e) {
      dispatch(notifiesActions.enqueueNotify("DISPOSITION_DELETE_FAIL"));
      return false;
    }
  };
};

const setRegion = (value) => {
  return (dispatch) =>
    dispatch({ type: "DISPOSITIONS_LOCATION_BY_REGION", payload: value });
};
const setProvince = (value) => {
  return (dispatch) =>
    dispatch({ type: "DISPOSITIONS_LOCATION_BY_PROVINCE", payload: value });
};
const setTypeOfTreatment = (value) => {
  return (dispatch) =>
    dispatch({ type: "DISPOSITIONS_LOCATION_BY_TYPE_OF_TREATMENT", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "DISPOSITIONS_SET_DATA_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "DISPOSITIONS_SET_DELETE_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "DISPOSITIONS_SET_EDIT_MODAL", payload: value });
};

export default {
  fetchDispositions,
  fetchDispositionsLocationsByRegions,
  postDisposition,
  updateDisposition,
  deleteDisposition,
  setRegion,
  setProvince,
  setTypeOfTreatment,
  setDataModal,
  setEditModal,
  setDeleteModal,
};