import React from "react";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import validator from "email-validator";
import axios from 'axios';
// import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
// import Geocode from "react-geocode";

import TableLocals from "./TableLocals";
import regProvComsData from "../../../../resources/regProvCom";
import { getDataCreateLocal } from "../../../../api/costumersApi";
import { getSuscriptions } from "../../../../api/suscriptionsApi";
import Map from "../Shared/Map";
import functions from "../../../../resources/functions";

export default function ModalCreate({ setLocalsData, localsData }) {
  const [addLocal, setAddLocal] = React.useState(false);
  const [status, setStatus] = React.useState(0);
  const [locals, setLocals] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [isManualPosition, setIsManualPosition] = React.useState(false);
  const [manualPosition, setManualPosition] = React.useState("");
  const [regions] = React.useState([]);
  const [provinces] = React.useState([]);
  const [communes] = React.useState([]);
  const [isRegionSelected, setIsRegionSelected] = React.useState(true);
  const [isProvinceSelected, setIsProvinceSelected] = React.useState(true);
  const [customers, setCustomers] = React.useState([]);
  const [suscriptions, setSuscriptions] = React.useState([]);
  const [formData, setFormData] = React.useState({
    customerID: "",
    name: "",
    address: "",
    suscriptionID: "",
    phone: "",
    email: "",
    contactName: "",
    createAsUser: false,
    region: "",
    province: "",
    commune: "",
    position: {
      latitude: -33.436988,
      longitude: -70.634391
    },
  });

  const loading = open && options.length === 0 && formData.address !== "";

  React.useEffect(() => {
    getDataCreateLocal().then((response) => setCustomers(response));
    getSuscriptions().then((response) => setSuscriptions(response));
  }, []);

  React.useState(() => {
    setLocals(localsData);
  }, [localsData]);

  React.useEffect(() => {
    if (formData.phone < 0) {
      setFormData({ ...formData, phone: 0 });
    }

    if (
      !formData.customerID ||
      !formData.name ||
      !formData.address ||
      !formData.phone ||
      !formData.suscriptionID ||
      formData.position.latitude === -33.436988 ||
      formData.position.longitude === -70.634391 ||
      !validator.validate(formData.email)
    ) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  }, [formData]);

  React.useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 1000);
    }
  }, [error]);

  React.useEffect(() => {
    if (formData.address === "") {
      return undefined;
    }
    (async () => {
      const endpoint = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
      const token = process.env.REACT_APP_MAPGL_API_KEY;

      let timeout = 0
      if (timeout) clearTimeout(timeout);
      const query = encodeURIComponent(formData.address + ".json");
      const previus = formData.address;

      if (previus !== formData.address) {
        return undefined;
      }
      timeout = setTimeout(async () => {
        const response = await axios.get(endpoint + query, {
          params: { access_token: token },
        });
        const payload = await response.data.features;
        setOptions(payload.map((result) => {
          if (result.length === 0) return
          return {
            name: result.place_name,
            coords: {
              lat: result.center[1],
              long: result.center[0],
            },
            address: result.place_name,
          };
        })
        );
      }, 2500);
    })();
  }, [formData.address])

  // REGIONES PROVINCIAS Y COMUNAS
  React.useEffect(() => {
    regProvComsData.forEach(regionsElements => {
      regions.push({
        name: regionsElements.region,
        code: regionsElements.region_iso_3166_2
      })
    })
  }, [])

  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleProvinces = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    provinces.splice(0, provinces.length);
    communes.splice(0, communes.length);
    setIsRegionSelected(false);
    setIsProvinceSelected(true);
    regProvComsData.map(element => {
      if (element.region_iso_3166_2 === e.target.value) {
        element.provincias.map(province => {
          provinces.push({ name: province.name, communes: province.comunas })
        })
      }
    })
  };

  const handleCommunes = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    communes.splice(0, communes.length);
    setIsProvinceSelected(false);
    provinces.map(province => {
      if (province.name === e.target.value) {
        province.communes.map(commune => {
          communes.push({ name: commune.name, code: commune.code })
        })
      }
    })
  };

  const handleManualPositions = () => {
    if (manualPosition.length < 22) { return setError(true) }
    else {
      let latitudeAux = manualPosition.toString().slice(0, 10);
      let longitudeAux = manualPosition.toString().slice(11, 22);
      setFormData({
        ...formData,
        position: {
          latitude: parseFloat(latitudeAux),
          longitude: parseFloat(longitudeAux)
        }
      })
    }
  }

  const addLocalToArray = () => {
    let aux = [];
    locals.forEach((element) => {
      aux.push(element);
    });
    aux.push(formData);
    setLocals(aux);
    setLocalsData(aux);
    discardLocal();
  };

  const deleteLocal = (key) => {
    let aux = [];
    locals.forEach((element, i) => {
      if (parseInt(i, 0) !== parseInt(key, 0)) {
        aux.push(element);
      }
    });
    setLocals(aux);
    setLocalsData(aux);
  };

  const discardLocal = () => {
    setAddLocal(false);
    setFormData({
      customerID: "",
      name: "",
      address: "",
      suscriptionID: "",
      phone: "",
      email: "",
      contactName: "",
      createAsUser: false,
      region: "",
      province: "",
      communes: "",
      position: {
        latitude: -33.436988,
        longitude: -70.634391
      },
    });
  };

  const style = {
    margin: "dense",
    variant: "outlined",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <div>
      <hr></hr>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button
            size="small"
            color={addLocal ? "primary" : "secondary"}
            onClick={
              addLocal ? () => discardLocal() : () => setAddLocal(!addLocal)
            }
            variant={addLocal ? "outlined" : "text"}
          >
            {addLocal ? "- Descartar local" : "+ Nuevo local"}
          </Button>
        </Grid>
        <Grid item>
          {addLocal && (
            <Button
              size="small"
              color={status ? "secondary" : "default"}
              variant={status ? "contained" : "outlined"}
              onClick={status ? () => addLocalToArray() : () => setError(true)}
              startIcon={<AddCircleOutlineIcon />}
            >
              Agregar
            </Button>
          )}
        </Grid>
      </Grid>
      <br></br>
      {addLocal && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="customerID"
                    label="Cliente / empresa"
                    value={formData.customerID}
                    onChange={(e) => updateData(e)}
                    select
                    SelectProps={{ native: true }}
                    error={!formData.customerID && error}
                    {...style}
                  >
                    <option value={""}>Ninguna</option>

                    {customers.map((customer, i) => (
                      <option key={i} value={customer._id}>
                        {customer.brand}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="name"
                    label="Nombre local"
                    value={formData.name}
                    onChange={(e) => updateData(e)}
                    error={!formData.name && error}
                    {...style}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} sm={3}>
                  {!isManualPosition ?
                    (<Autocomplete
                      id="asynchronous-demo"
                      open={open}
                      onOpen={() => { setOpen(true) }}
                      onClose={() => { setOpen(false) }}
                      getOptionLabel={(option) => option.name}
                      onChange={async (e, k) => {
                        setFormData({
                          ...formData,
                          address: k.address,
                          position: {
                            latitude: k.coords.lat,
                            longitude: k.coords.long
                          }
                        })
                      }}
                      options={options}
                      loading={loading}
                      renderInput={(params) => (
                        <TextField
                          label="Dirección"
                          margin="dense"
                          variant="outlined"
                          error={!formData.address && error}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                          onChange={(e) =>
                            setFormData({ ...formData, address: e.target.value })
                          }
                        />
                      )}
                    />)
                    :
                    (<TextField
                      name="address"
                      label="Dirección"
                      value={formData.address}
                      onChange={(e) => updateData(e)}
                      error={!formData.address && error}
                      {...style}
                    />)
                  }
                </Grid>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="region"
                    label="Región"
                    value={formData.region}
                    select
                    SelectProps={{ native: true }}
                    onChange={(e) => handleProvinces(e)}
                    error={!formData.region && error}
                    {...style}
                  >
                    <option value={""}>Ninguna</option>

                    {regions.map((region, i) => (
                      <option
                        key={`op${i}-${region.code}`}
                        value={region.code}
                      >
                        {region.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="province"
                    label="Provincia"
                    value={formData.province}
                    disabled={isRegionSelected}
                    select
                    SelectProps={{ native: true }}
                    onChange={(e) => handleCommunes(e)}
                    error={!formData.province && error}
                    {...style}
                  >
                    <option value="">Ninguna</option>

                    {provinces.map((province, i) => (
                      <option
                        key={`op${i}-${province.code}`}
                        value={province.code}
                      >
                        {province.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="commune"
                    label="Comuna"
                    value={formData.commune}
                    disabled={isProvinceSelected}
                    select
                    SelectProps={{ native: true }}
                    onChange={(e) => updateData(e)}
                    error={!formData.commune && error}
                    {...style}
                  >
                    <option value="">Ninguna</option>

                    {communes.map((commune, i) => (
                      <option
                        key={`op${i}-${commune.code}`}
                        value={commune.code}
                      >
                        {commune.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="suscriptionID"
                    label="Suscripción"
                    value={formData.suscriptionID}
                    onChange={(e) => updateData(e)}
                    type="number"
                    select
                    SelectProps={{ native: true }}
                    error={!formData.suscriptionID && error}
                    {...style}
                  >
                    <option value={""}>Ninguna</option>

                    {suscriptions.map((element, i) => (
                      <option value={element._id} key={i}>
                        {element.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="contactName"
                    label="Nombre contacto"
                    value={formData.contactName}
                    onChange={(e) => updateData(e)}
                    onClick={() => setFormData({ ...formData })}
                    error={!formData.contactName && error}
                    {...style}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="email"
                    label="Email"
                    value={formData.email}
                    onChange={(e) => updateData(e)}
                    onClick={() => setFormData({ ...formData })}
                    error={!validator.validate(formData.email) && error}
                    {...style}
                  />
                </Grid>
                <Grid item md={6} sm={3}>
                  <TextField
                    name="phone"
                    label="Teléfono"
                    value={functions.formatPhone(formData.phone)}
                    onChange={(e) => updateData(e)}
                    onClick={() => setFormData({ ...formData })}
                    error={!formData.phone && error}
                    {...style}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} sm={6}>
                  <FormControlLabel
                    label="Crear contacto como usuario de la plataforma"
                    control={
                      <Checkbox
                        value={formData.createAsUser}
                        checked={formData.createAsUser}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            createAsUser: event.target.checked,
                          })
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item md={6} sm={6}>
                  <FormControlLabel
                    label="Agregar coordenadas manual"
                    control={
                      <Checkbox
                        checked={isManualPosition}
                        onChange={(event) => setIsManualPosition(event.target.checked)}
                      />
                    }
                  />
                </Grid>
              </Grid>
              {isManualPosition &&
                <Grid container spacing={1}>
                  <Grid item md={6} sm={6}>
                    <TextField
                      name="manualCoordinates"
                      label="Latitud y longitud"
                      onChange={(e) => setManualPosition(e.target.value)}
                      error={(!formData.position && !isManualPosition) && error}
                      {...style}
                    />
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <Button
                      onClick={handleManualPositions}
                      color={"primary"}
                    >
                      actualizar coordenadas
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item xs={6}>
              <Map formData={formData} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </div>
      )}
      <hr></hr>
      <TableLocals
        locals={locals}
        deleteLocal={deleteLocal}
        customers={customers}
        suscriptions={suscriptions}
      />
    </div>
  );
}
