import React from "react";

import truck_recycling from "../../../../../resources/images/truck_recycling.png"
import truck_garbage from "../../../../../resources/images/truck_garbage.png"

const pinStyle = {
  cursor: "pointer",
  fill: "#d00",
  stroke: "none",
};

export default function DispositionPin({ size, onClick, data }) {
  const [isGarbageTruck, setIsGarbageTruck] = React.useState(false);

  React.useEffect(() => {
    data.typeOfTreatment.forEach(element => {
      if (element.subTypeName === "Eliminación") {
        setIsGarbageTruck(true)
      }
    });
  }, [])

  return (
    <img
      src={isGarbageTruck ? truck_garbage : truck_recycling}
      style={{
        ...pinStyle,
        // transform: `translate(${-size / 2}px,${-size}px)`,
        width: "9%",
      }}
      onClick={onClick}
      alt="type_of_treatment"
    />
  );
}
