import React from "react";
import { Card, Grid, Typography, Tooltip } from "@material-ui/core";
import LinearProgressFill from "../LinearProgressFill";
import PropTypes from "prop-types";
import StoreIcon from "@material-ui/icons/Store";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Marker } from "react-map-gl";
import { useSensorsContext } from "../../../../context/SensorsContext";

export default function Description({ data , sensor, sensorOrder}) {
  const [open, setOpen] = React.useState(false);
  const { helpers } = useSensorsContext();
  return (
    <Marker
      longitude={data.position.longitude}
      latitude={data.position.latitude}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Tooltip title={data.local}>
            <LocationOnIcon
              style={{
                fontSize: 44,
                color:
                    sensor<= 30
                        ? "#6ecb4c"
                        : sensor <= 60
                        ? "#ffbb33"
                        : "#ff4444",
              }}
              onClick={() => setOpen(!open)}
            />

          </Tooltip>
        </Grid>
        {open && (
          <Grid item align="center"  >
            <Card style={{ padding: 16, minWidth: 200, zIndex:2 }}>
              <Typography component="h5">
                <div><StoreIcon /> {data.local}</div>
                <div>{data.address}</div>
              </Typography>
              <hr></hr>
              {!data.sensors.length && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ width: 180 }}
                >
                  Esta sucursal no cuenta con sensores
                </Typography>
              )}
              {data.sensors.map((sensor, i) => (
                <div key={"pointer_filled_" + i}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="left"
                  >
                    {helpers[sensorOrder[i].material.toLowerCase()].name}
                  </Typography>
                  <LinearProgressFill value={sensorOrder[i].fill} />
                </div>
              ))}
            </Card>
          </Grid>
        )}
      </Grid>
    </Marker>
  );
}

Description.propTypes = {
  data: PropTypes.object.isRequired,
};
