import React from "react";
import { useCustomersContext } from "../../../../context/CustomersContext";
import { Grid } from "@material-ui/core";
import StateCard from "./StateCards";

function Cards() {
  const { setSelect, customers, select } = useCustomersContext();

  const [custQ, setCusQ] = React.useState(0);
  const [localsQ, setLocalsQ] = React.useState(0);

  React.useEffect(() => {
    if (customers.length) {
      let aux = 0;
      customers.forEach((element) => {
        aux += element.localsID.length;
      });
      setCusQ(customers.length);
      setLocalsQ(aux);
    }
  }, [customers]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6} onClick={() => setSelect("CUSTOMER")}>
        <StateCard
          title="CLIENTES"
          value={custQ ? custQ : "..."}
          background={select === "CUSTOMER" ? "#00C851" : "#2E2E2E"}
        />
      </Grid>
      <Grid item sm={3} xs={6} onClick={() => setSelect("LOCAL")}>
        <StateCard
          title="SEDES / SUCURSALES"
          value={localsQ ? localsQ : "..."}
          background={select === "LOCAL" ? "#00C851" : "#2E2E2E"}
        />
      </Grid>
    </Grid>
  );
}

export default Cards;
