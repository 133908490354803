import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Paper, Card, CardContent, Grid, Button,TextField,MenuItem,InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import CreateModal from "./ModalCreate";
import TransportersList from "./TransportersList";

import transportersActions from "../../../redux/actions/transporters";

import SearchIcon from "@material-ui/icons/Search";
import removalsActions from "../../../redux/actions/removals";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TransportersPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const transporters = useSelector((state) => state.transporters);

  const [createModal, setCreateModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const [typeSearch, setTypeSearch] = useState("ALL");
  const [firstParam, setFirstParam] = useState("");
  const [secondParam, setSecondParam] = useState("");
  const [search, setSearch] = React.useState("")

  useEffect(() => {
    // const start = async () => {
    dispatch(transportersActions.fetchTransporters());
    // };
    // start();
  }, []);

  const searchOnClick = () => {
    var data = null;
    if (typeSearch === "TRANSPORTER") {
      data = {
        type: "TRANSPORTER",
        firstParam,
      };
    }
    if (typeSearch === "ENTERPRISE") {
      data = {
        type: "ENTERPRISE",
        firstParam: {
          payment: { $gte: firstParam, $lte: secondParam },
        },
      };
    }
    if (typeSearch === "ADDRESS") {
      data = {
        type: "ADDRESS",
        firstParam: {
          status: firstParam,
        },
      };
    }
    if (typeSearch === "NAME") {
      data = {
        type: "NAME",
        firstParam: {
          status: firstParam,
        },
      };
    }
    if (typeSearch === "ALL") {
      data = {
        type: "ALL",
        firstParam: {},
      };
    }
    dispatch(removalsActions.setSearch(data));
  };

  return (
    <Paper className={classes.root}>
      {createModal && (
        <CreateModal setOpen={setCreateModal} />
      )}
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <div>
                <h3>Transportistas</h3>
              </div>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setCreateModal(true)}
                color="primary"
                size="small"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
              >
                Agregar Transportista
              </Button>
            </Grid>
          </Grid>
          <hr></hr>
          <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="outlined-required"
            label="Tipo de búsqueda"
            variant="outlined"
            margin="dense"
            type="text"
            value={typeSearch}
            onChange={(e) => setTypeSearch(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            select
            fullWidth
          >
            <MenuItem value="ALL">Todos</MenuItem>
            <MenuItem value="ENTERPRISE">Empresa</MenuItem>
            <MenuItem value="ADDRESS">Dirección</MenuItem>
            <MenuItem value="NAME">Nombre</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
        {typeSearch === "ENTERPRISE" ? (
            <TextField
              id="outlined-required"
              label="Buscar"
              variant="outlined"
              margin="dense"
              type="text"
              onChange={(e) => setFirstParam(e.target.value)}
              value={firstParam}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          ) : typeSearch === "ADDRESS" ? (
            <TextField
            id="outlined-required"
            label="Buscar"
            variant="outlined"
            margin="dense"
            type="text"
            onChange={(e) => setFirstParam(e.target.value)}
            value={firstParam}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          ) : typeSearch === "NAME" ? (
            <TextField
            id="outlined-required"
            label="Buscar"
            variant="outlined"
            margin="dense"
            type="text"
            onChange={(e) => setFirstParam(e.target.value)}
            value={firstParam}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          ) : ("")}
          </Grid>
          <Grid item xs={3}>
          {typeSearch !== "ALL" ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => searchOnClick()}
              style={{ marginTop: 10 }}
              startIcon={<SearchIcon />}
              fullWidth
            >
              Buscar
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
          <TransportersList
            transporters={transporters}
            editData={editData}
            setEditData={setEditData}
          />
        </CardContent>
      </Card>
    </Paper>
  );
}

export default TransportersPage;
