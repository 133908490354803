import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  ButtonGroup,
  Button,
  Chip,
  Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import EcoIcon from "@material-ui/icons/Eco";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useSelector, useDispatch } from "react-redux";

import removalsActions from "../../../redux/actions/removals";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Solicitante",
  },
  {
    id: "transporter",
    numeric: false,
    disablePadding: false,
    label: "Transportista",
  },
  {
    id: "datetimeRemoval",
    numeric: false,
    disablePadding: false,
    label: "Fecha de retiro",
  },
  { id: "payment", numeric: true, disablePadding: false, label: "Pago CLP" },
  { id: "author", numeric: false, disablePadding: false, label: "ROL" },
  {
    id: "data",
    numeric: false,
    disablePadding: false,
    label: "Datos",
  },
  {
    id: "estado",
    numeric: false,
    disablePadding: false,
    label: "Estado transporte",
  },
  { id: "options", numeric: false, disablePadding: false, label: "Opciones" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({ page, setPage }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("datetimeRequest");
  const [selected, setSelected] = React.useState([]);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = React.useState([]);

  const dispatch = useDispatch();
  const removals = useSelector((state) => state.removals);

  React.useEffect(() => {
    if (removals.removals) {
      console.log(removals);
      setRows(removals.removals);
    }
  }, [removals.removals]);

  console.log(new Date(rows.datetimeRemoval))

  const openModalViewExtra = (data) => {
    dispatch(removalsActions.setViewExtraModal(true));
    dispatch(removalsActions.setDataExtraModal(data));
  };

  const openModalEdit = (data) => {
    dispatch(removalsActions.setCreateModal(true));
    dispatch(removalsActions.setDataModal(data));
  };

  const openModalDelete = (data) => {
    dispatch(removalsActions.setDeleteModal(true));
    dispatch(removalsActions.setDataModal(data));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = async (event, newPage) => {
    try {
      // Assuming fetchRemovals is an async action that returns a promise
      await dispatch(removalsActions.fetchRemovals(page));
  
      // After removals are fetched successfully, update the page
      setPage(newPage);
      console.log(removals.count);
    } catch (error) {
      // Handle any errors that occur during fetching
      console.error("Error fetching removals:", error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const textState = (state) => {
    switch(state){
      case "PENDING_VALID":
        return "Validación pendiente";
      break;
      case "COMPLETED":
        return "Completo";
      break;
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const translateAuthor = (author) => {
    if (author === "ADMIN") {
      return "admin";
    }
    if (author === "WEBAPP_SUSCRIPTION") {
      return "usr susc";
    }
    if (author === "WEBAPP_EXTRA") {
      return "usr extra";
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(index);
					var s1=row.datetimeRemoval.split("T");
					var s2=s1[0].split("-");
					var fecha= s2[2] + "-" + s2[1] + "-" + s2[0] ;
		
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <StyledTableCell align="left">
                        {row.localID?.customerID?.brand +
                          " " +
                          row.localID?.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {!row.transporterID
                          ? "-"
                          : row.transporterID.name +
                            " " +
                            row.transporterID.lastName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {
							
						row.datetimeRemoval
                          ? fecha
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.payment ? (
                          <NumberFormat
                            value={Math.round(row.payment, 0)}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"$"}
                          />
                        ) : (
                          "-"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Chip
                          size="small"
                          label={translateAuthor(row.author)}
                          color="secondary"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.transporterID ? (
                          <LocalShippingIcon style={{ color: "00C851" }} />
                        ) : (
                          <LocalShippingIcon style={{ color: "#BDBDBD" }} />
                        )}{" "}
                        {row.payment !== 0 ? (
                          <AttachMoneyIcon style={{ color: "00C851" }} />
                        ) : (
                          <AttachMoneyIcon style={{ color: "#BDBDBD" }} />
                        )}{" "}
                        {removals.selector === "COMPLETE" ? (
                          <EcoIcon style={{ color: "00C851" }} />
                        ) : (
                          <EcoIcon style={{ color: "#BDBDBD" }} />
                        )}{" "}
                        {row.urlReport ? (
                          <AssignmentTurnedInIcon
                            style={{ color: "00C851" }}
                            onClick={() => window.open(row.urlReport)}
                          />
                        ) : (
                          <AssignmentTurnedInIcon
                            style={{ color: "#BDBDBD" }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                      {textState(row.statusTransport)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <ButtonGroup size="small" variant="contained">
                          <Button
                            color="primary"
                            onClick={removals.selector === "EXTRA" ? () =>  openModalViewExtra(row) : () =>  openModalEdit(row)}
                          >
                            <VisibilityIcon />
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => openModalEdit(row)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => openModalDelete(row)}
                          >
                            <DeleteIcon />
                          </Button>
                        </ButtonGroup>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {rows.length ? (
            ""
          ) : (
            <Typography style={{ margin: 24 }}>
              No se encontraron resultados
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[15]}
          component="div"
          count={removals.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
