const defaultState = {
  reports: [],
  createModal: false,
  deleteModal: false,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "REPORTS_FETCH": {
      return { ...state, reports: payload };
    }
    case "REPORTS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "REPORTS_SET_CREATE_EXCEL_MODAL": {
      return { ...state, createModal: payload };
    }
    case "REPORTS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "REPORTS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    default:
      return state;
  }
}
export default reducer;
