import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import Customers from "./Customers";
import { updateCustomer } from "../../../../api/costumersApi";
import { useCustomersContext } from "../../../../context/CustomersContext";
import CachedIcon from "@material-ui/icons/Cached";
import CloseIcon from "@material-ui/icons/Close";

export default function ModalCreate() {
  const { setViewModalEditCustomer, data, updateCustomer } = useCustomersContext();
  const [status, setStatus] = React.useState(0);

  const [formData, setFormData] = React.useState({
    brand: "",
    rut: "",
    businessName: "",
    businessActivity: "",
    addressSociety: "",
    legalRepresentName: "",
    legalRepresentRut: "",
    _id: data._id,
  });

  React.useEffect(() => {
    if (
      !formData.brand ||
      !formData.rut ||
      !formData.businessName ||
      !formData.businessActivity ||
      !formData.addressSociety ||
      !formData.legalRepresentName ||
      !formData.legalRepresentRut
    ) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  }, [formData]);

  React.useEffect(() => {
    setFormData({
      brand: data.brand,
      rut: data.rut,
      businessName: data.businessName,
      businessActivity: data.businessActivity,
      addressSociety: data.addressSociety,
      legalRepresentName: data.legalRepresentName,
      legalRepresentRut: data.legalRepresentRut,
      _id: data._id,
    });
  }, [data]);

  const handleClose = () => {
    setViewModalEditCustomer(false);
  };

  const handleNext = () => {
    if (status) {
      updateCustomer(formData).then((_response) => {
        setViewModalEditCustomer(false);
      });
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <h4>Editar cliente</h4>
          </Grid>
        </Grid>
        <Customers formData={formData} setFormData={setFormData} />
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small">
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            startIcon={<CloseIcon />}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleNext}
            color={status ? "secondary" : ""}
            variant="contained"
            startIcon={<CachedIcon />}
          >
            actualizar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
