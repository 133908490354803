import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Grow,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  TextField,
  withStyles,
} from "@material-ui/core";
import Header from "../../Navigation/Header";
import Tabla from "./Tabla";
import { useSelector, useDispatch } from "react-redux";
import learningActions from "../../../redux/actions/learning";
import axios from "axios";
/* import { DropzoneArea } from "material-ui-dropzone"; */

function Index() {
  const [viewState, setViewState] = React.useState(false);
  const dispatch = useDispatch();
  const learning = useSelector((state) => state.learning);

  useEffect(() => {
    const start = async () => {
      dispatch(learningActions.fetchLearning());
    };
    start();
  }, []);

  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [values, setValues] = useState({
    title: "",
    description: "",
  });

  const handleChange = (e) => {
    setVideo(e.target.files[0]);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  /* useEffect(() => {
    setFormData({
      ...formData,
      title: values.title,
      description: values.description,
      videoLearning: video
    })
  },[formData.title, formData.description, formData.videoLearning]) */

  async function handleUpload(e) {
    
    e.preventDefault();
    setIsUploading(true);
    const formData = new FormData();
    formData.append("videoLearning", video);
    formData.append("title", values.title);
    formData.append("description", values.description);
    

    try {
      const response = await dispatch(learningActions.createLearning(formData));
      //setIsUploaded(true);
      //setIsUploading(false);
      
        handleClose();
      
    } catch (err) {
      setError(err.message);
      setIsUploading(false);
    }
  }

  const handleClose = () => {
    setViewState(false);
  };

  return (
    <Card>
      <CardContent>
        <Header
          title={
            <Typography component="h2" variant="h4">
              Aprendizaje
            </Typography>
          }
        />
        <hr />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={4} md={3}>
            <Button
              size="small"
              color="primary"
              style={{
                background:
                  "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                color: "#fff",
              }}
              variant="contained"
              onClick={() => setViewState(!viewState)}
              fullWidth
            >
              Nuevo aprendizaje
            </Button>
          </Grid>
        </Grid>

        {/* Dialogo */}
        {viewState && (
          <Dialog
            open={viewState}
            aria-labelledby="form-dialog-title"
            scroll="body"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">Nuevo Aprendizaje</DialogTitle>
            <form onSubmit={handleUpload}>
              <DialogContent>
                <TextField
                  name="title"
                  margin="dense"
                  variant="outlined"
                  label="Titulo del video"
                  fullWidth
                  onChange={handleChange1}
                  value={values.title}
                />
                <Grid item xs={12}>
                  <label
                    htmlFor="raised-button-file-2"
                    style={{ width: "100%" }}
                  >
                    <input type="file" onChange={handleChange} />
                  </label>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="description"
                    margin="dense"
                    variant="outlined"
                    label="Descripción del video"
                    placeholder="Escribe aquí toda la información que consideres relevante."
                    fullWidth
                    rows={6}
                    multiline
                    onChange={handleChange1}
                    value={values.description}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid item xs={6}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="default"
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button type="submit" variant="contained" color="success">
                    Aceptar
                  </Button>
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
        )}

        <hr />
        <Tabla />
      </CardContent>
    </Card>
  );
}

export default Index;
