import React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { useCustomersContext } from "../../../../context/CustomersContext";

export default function ModalDelete() {
  const {
    data,
    setViewDelete,
    deleteCustomer,
    deleteLocal,
  } = useCustomersContext();
  const [name, setName] = React.useState(null);
  const [type, setType] = React.useState("CUSTOMER");

  React.useEffect(() => {
    if (data) {
      setName(data.name ? data.name : data.brand);
      setType(data.name ? "LOCAL" : "CUSTOMER");
    }
  }, [data]);
  const handleClose = () => {
    setViewDelete(false);
  };
  const handleDeleteRemoval = async () => {
    if (type === "CUSTOMER") {
      await deleteCustomer(data._id);
      setViewDelete(false);
    } else {
      await deleteLocal(data.element._id);
      setViewDelete(false);
    }
  };
  return (
    <Dialog open={true} onClose={handleClose} scroll={"body"}>
      <DialogContent>
        <h4>{type === "CUSTOMER" ? "Eliminar cliente" : "Eliminar local"}</h4>
        <hr></hr>
        <h5>
          {type === "CUSTOMER"
            ? "¿Seguro que quieres eliminar el cliente " + name + "?"
            : "¿Seguro que quieres eliminar el local " + name + "?"}
        </h5>
        <br></br>

        <Grid container>
          <Grid item xs={12}>
            <p>
              Si eliminas este cliente ya no podrás asignarle más retiros ni ver
              sus estadísticas. Todos los movimientos y datos generados
              anteriormente a este cliente no se verán afectados.
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleClose()}
            startIcon={<CloseIcon />}
          >
            cancelar
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleDeleteRemoval()}
            startIcon={<CheckIcon />}
          >
            Sí
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
