import axios from "axios";
import notifiesActions from "./notifies";

const fetchLearning = () => {
    return async (dispatch) => {
      try {
        const response = await axios.get("/adminTool/learning");
        dispatch({ type: "LEARNING_FETCH", payload: response.data });
		/*var x=response;
		console.log("piaaaa" + x.data.version);*/
        return true;
      } catch (e) {
        return false;
      }
    };
  };

  const createLearning = (formData) => {
    return async (dispatch) => {
      try {
        
        const response = await axios.post("/admintool/learning/create", formData, {headers: {
          'Content-Type': 'multipart/form-data'
          }});
          dispatch(fetchLearning());
          
          dispatch(notifiesActions.enqueueNotify("REMOVAL_LEARNING_SUCCESS"));
          
        return response.data;
      } catch (e) {
        return false;
      }
    };
  };
  
  const viewDisplay = (formData) => {
    return async (dispatch) => {
      try {
        
        const response = await axios.post("/admintool/learning/display", formData, {headers: {
          'Content-Type': 'multipart/form-data'
          }});
          var x = response.data;
          console.log(x)

        return x;
      } catch (e) {
        return false;
      }
    };
  };

  const updateLearning = (formData) => {
    return async (dispatch) => {
      try {
        debugger;
        const response = await axios.post("/admintool/learning/update", formData, {headers: {
          'Content-Type': 'multipart/form-data'
          }});
        return response.data;
      } catch (e) {
        return false;
      }
    };
  };

  export default {
    fetchLearning,
    createLearning,
    viewDisplay,
    updateLearning
  };