export default [
  { name: "Rellenaro sanitario", code: 11, subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1"},
  { name: "Vertedero", code: 12, subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1" },
  { name: "Monorelleno", code: 14, subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1" },
  { name: "Basural", code: 30, subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1" },
  { name: "Recepción de lodos en PTAS", code: 32, subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1" },
  { name: "Depósito de seguridad", code: 33, subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1" },
  { name: "Sitio de Escombros de la Construcción", code:38,  subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1" },
  { name: "Depósito de Cenizas", code:39, subTypeName: "Eliminación", subTypeCode: "ELIM-DIS-FIN", estado:"1" },
  { name: "Incineración sin recuperación de energía", code: 4, subTypeName: "Eliminación", subTypeCode: "ELIM-ISR-ENE", estado:"0" },
  { name: "Reducción de recursos hidrobiológicos", code: 34, subTypeName: "Valorización", subTypeCode: "VALO-RRH", estado:"0" },
  { name: "Papel, Cartón y productos del papel", code: 16, subTypeName: "Valorización", subTypeCode: "VALO-RJE-PCP", estado:"0" },
  { name: "Textiles", code: 17, subTypeName: "Valorización", subTypeCode: "VALO-RJE-TEX", estado:"0" },
  { name: "Plásticos", code: 18, subTypeName: "Valorización", subTypeCode: "VALO-RJE-PLA", estado:"0" },
  { name: "Vidrios", code: 19, subTypeName: "Valorización", subTypeCode: "VALO-RJE-VID", estado:"0" },
  { name: "Metales", code: 20, subTypeName: "Valorización", subTypeCode: "VALO-RJE-MET", estado:"0" },
  { name: "Residuos voluminosos", code: 22, subTypeName: "Valorización", subTypeCode: "VALO-RJE-RVO", estado:"0" },
  { name: "Co-incineración", code: 24, subTypeName: "Valorización", subTypeCode: "VALO-REN-COI", estado:"0" },
  { name: "Incineración con recuperación de energía", code: 25, subTypeName: "Valorización", subTypeCode: "VALO-REN-IRE", estado:"0" },
  { name: "Preparación para valorización", code: 35, subTypeName: "Valorización", subTypeCode: "VALO-PVA", estado:"0" }, 
  { name: "Reciclaje",code: 2, subTypeName: "Valorización", subTypeCode: "VALO-RJE", estado:"1" },
  { name: "Recuperación de energía",code: 3, subTypeName: "Valorización", subTypeCode: "VALO-RE", estado:"1" },
  { name: "Recepción de Lodos de PTAS",code: 5, subTypeName: "Valorización", subTypeCode: "VALO-RLP", estado:"1" },
  { name: "Co-procesamiento", code: 23, subTypeName: "Valorización", subTypeCode: "VALO-COP", estado:"1" },
  { name: "Compostaje", code: 27, subTypeName: "Valorización", subTypeCode: "VALO-COM", estado:"1" },
  { name: "Lombricultura", code: 28, subTypeName: "Valorización", subTypeCode: "VALO-LOM", estado:"1" },
  { name: "Degradación Anaeróbica", code: 29, subTypeName: "Valorización", subTypeCode: "VALO-DEA", estado:"1" },
  { name: "Aplicación al suelo", code: 31, subTypeName: "Valorización", subTypeCode: "VALO-ASU", estado:"1" },
  { name: "Reducción de Recursos",code: 8, subTypeName: "Valorización", subTypeCode: "VALO-RR", estado:"1" },
  { name: "Hidrobiológicos",code: 9, subTypeName: "Valorización", subTypeCode: "VALO-H", estado:"1" },
  { name: "Preparación para reutilización", code: 7, subTypeName: "Valorización", subTypeCode: "VALO-PRE", estado:"1" },
]