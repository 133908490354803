import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ModalCreate({ locals, deleteLocal, suscriptions }) {
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    setRows(locals);
  }, [locals, suscriptions]);

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nombre local</TableCell>
            <TableCell>Dirección</TableCell>
            <TableCell>Suscripción</TableCell>
            <TableCell>Opciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((element, i) => (
            <TableRow key={i}>
              <TableCell>{element.name}</TableCell>
              <TableCell>{element.address}</TableCell>
              <TableCell>
                {
                  suscriptions.filter(
                    (el) => el._id === element.suscriptionID
                  )[0].name
                }
              </TableCell>
              <TableCell>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => deleteLocal(i)}
                >
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
