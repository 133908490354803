import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Card,
  makeStyles,
} from "@material-ui/core";
import { getStats } from "../../../../api/costumersApi";

import { useCustomersContext } from "../../../../context/CustomersContext";
import MaterialList from "./MaterialList";
import EcoEq from "./EcoEq";
import GeneralData from "./GeneralData";
import Header from "./Header";
import Cards from "./Cards";
import RemovalList from "./RemovalList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    maxWidth: 610,
  },
  title: {
    margin: theme.spacing(1),
  },
}));

export default function ViewCustomer() {
  const { data, setViewCustomer } = useCustomersContext();
  const classes = useStyles();
  const now = new Date();

  const [mainData, setMainData] = React.useState({});
  const [locals, setLocals] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);
  const [ecoeq, setEcoeq] = React.useState([]);
  const [dateInit, setDateInit] = React.useState(
    new Date(now.getFullYear(), now.getMonth(), 1)
  );
  const [dateFinish, setDateFinish] = React.useState(new Date());
  const [selectDate, setSelectDate] = React.useState(0);
  const [removals, setRemovals] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      setMainData(data);
      setLocals(data.localsID);

      getStats({
        customerID: data._id,
        dateInit,
        dateFinish,
      }).then((response) => {
        setEcoeq(response.ecoeq);
        setRemovals(response.removals);
        setMaterials(response.totalMaterials);
      });
    }
  }, [data, dateInit, dateFinish]);

  React.useEffect(() => {
    const now = new Date();

    if (selectDate === 0) {
      setDateInit(new Date(now.getFullYear(), now.getMonth(), 1));
      setDateFinish(now);
    }
    if (selectDate === 1) {
      setDateInit(new Date(2018, 1, 1));
      setDateFinish(now);
    }
  }, [selectDate]);

  return (
    <Dialog
      open={true}
      onClose={() => setViewCustomer(false)}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogContent>
        <Header
          mainData={mainData}
          selectDate={selectDate}
          setSelectDate={setSelectDate}
          dateFinish={dateFinish}
          dateInit={dateInit}
          setDateFinish={setDateFinish}
          setDateInit={setDateInit}
        />
        <hr></hr>
        <Cards data={{ locals, removals, materials }} />
        <hr></hr>
        <GeneralData mainData={mainData} locals={locals} />
        <hr></hr>
        <EcoEq ecoeq={ecoeq} materials={materials} />
        <hr />
        <RemovalList removals={removals} />
        <hr />
        <Card>
          <h4 className={classes.title} style={{ textAlign: "center" }}>
            Kilos de material reciclados
          </h4>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <MaterialList materials={materials} />
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
