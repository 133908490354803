import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { Bar, Pie, Radar, Line } from "react-chartjs-2";

import { parseFlag } from "../../../resources/residuesTypes";

const roundNum = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    maxWidth: 610,
  },
  title: {
    margin: theme.spacing(1),
  },
}));

export default function ModalDelete({ data, uf }) {
  const classes = useStyles();

  const [removalsPerLocal, setRemovalsPerLocal] = React.useState({
    labels: [""],
    datasets: [
      {
        data: [0],
        backgroundColor: [
          "#F7464A",
          "#46BFBD",
          "#FDB45C",
          "#949FB1",
          "#4D5360",
          "#ac64ad",
        ],
        hoverBackgroundColor: [
          "#FF5A5E",
          "#5AD3D1",
          "#FFC870",
          "#A8B3C5",
          "#616774",
          "#da92db",
        ],
      },
    ],
  });
  const [removalsPerLocalPayment, setRemovalsPerLocalPayment] = React.useState({
    labels: [""],
    datasets: [
      {
        data: [0],
        backgroundColor: [
          "#F7464A",
          "#46BFBD",
          "#FDB45C",
          "#949FB1",
          "#4D5360",
          "#ac64ad",
        ],
        hoverBackgroundColor: [
          "#FF5A5E",
          "#5AD3D1",
          "#FFC870",
          "#A8B3C5",
          "#616774",
          "#da92db",
        ],
      },
    ],
  });
  const [profitPerLocal, setProfitPerLocal] = React.useState({
    labels: [""],
    datasets: [
      {
        data: [0],
        backgroundColor: [
          "#F7464A",
          "#46BFBD",
          "#FDB45C",
          "#949FB1",
          "#4D5360",
          "#ac64ad",
        ],
        hoverBackgroundColor: [
          "#FF5A5E",
          "#5AD3D1",
          "#FFC870",
          "#A8B3C5",
          "#616774",
          "#da92db",
        ],
      },
    ],
  });
  const [dataRemovalsPerDate, setDataRemovalsPerDate] = React.useState({
    labels: [""],
    datasets: [
      {
        data: [0],
        backgroundColor: [
          "#F7464A",
          "#46BFBD",
          "#FDB45C",
          "#949FB1",
          "#4D5360",
          "#ac64ad",
        ],
        hoverBackgroundColor: [
          "#FF5A5E",
          "#5AD3D1",
          "#FFC870",
          "#A8B3C5",
          "#616774",
          "#da92db",
        ],
      },
    ],
  });
  const [dataTotalMaterials, setDataTotalMaterials] = React.useState({
    labels: [""],
    datasets: [
      {
        data: [0],
        backgroundColor: [
          "#F7464A",
          "#46BFBD",
          "#FDB45C",
          "#949FB1",
          "#4D5360",
          "#ac64ad",
        ],
        hoverBackgroundColor: [
          "#FF5A5E",
          "#5AD3D1",
          "#FFC870",
          "#A8B3C5",
          "#616774",
          "#da92db",
        ],
      },
    ],
  });
  const [materials, setMaterials] = React.useState([]);
  const options = {
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  React.useEffect(() => {
    
    if (data) {
      
      setMaterials(data.totalMaterials);
      setDataTotalMaterials({
        labels: data.totalMaterials.map((element) => parseFlag(element._id)),
        datasets: [
          {
            data: data.totalMaterials.map((element) => element.quantity),
            //backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#ac64ad'],
            hoverBackgroundColor: [
              "#FF5A5E",
              "#5AD3D1",
              "#FFC870",
              "#A8B3C5",
              "#616774",
              "#da92db",
            ],
            backgroundColor: "#33b5e5",
            label: "Kilos por material",
          },
        ],
      });
      setRemovalsPerLocal({
        labels: data.removalsPerLocal.map(
          (element) => element._id.customerID.brand + " " + element._id.name
        ),
        datasets: [
          {
            data: data.removalsPerLocal.map((element) => element.quantity),
            backgroundColor: [
              "#F7464A",
              "#46BFBD",
              "#FDB45C",
              "#949FB1",
              "#4D5360",
              "#ac64ad",
            ],
            hoverBackgroundColor: [
              "#FF5A5E",
              "#5AD3D1",
              "#FFC870",
              "#A8B3C5",
              "#616774",
              "#da92db",
            ],
          },
        ],
      });
      setRemovalsPerLocalPayment({
        labels: data.removalsPerLocal.map(
          (element) => element._id.customerID.brand + " " + element._id.name
        ),
        datasets: [
          {
            data: data.removalsPerLocal.map((element) => element.total),
            backgroundColor: [
              "#F7464A",
              "#46BFBD",
              "#FDB45C",
              "#949FB1",
              "#4D5360",
              "#ac64ad",
            ],
            hoverBackgroundColor: [
              "#FF5A5E",
              "#5AD3D1",
              "#FFC870",
              "#A8B3C5",
              "#616774",
              "#da92db",
            ],
          },
        ],
      });
      setProfitPerLocal({
        labels: data.removalsPerLocal.map(
          (element) => element._id.customerID.brand + " " + element._id.name
        ),
        datasets: [
          {
            stack: 0,
            label: "Pagos en retiros",
            data: data.removalsPerLocal.map((element) => element.total),
            backgroundColor: "#ffbb33",
          },
          {
            stack: 1,
            label: "Ingreso por suscripción",
            data: data.removalsPerLocal.map((element) =>
              roundNum(element._id.suscription * uf)
            ),
            backgroundColor: "#33b5e5",
          },
          {
            stack: 2,
            label: "Utilidad",
            data: data.removalsPerLocal.map((element) =>
              roundNum(element._id.suscription * uf - element.total)
            ),
            backgroundColor: data.removalsPerLocal.map((element) => {
              if (element._id.suscription * uf - element.total >= 0) {
                return "#00C851";
              } else {
                return "#ff4444";
              }
            }),
          },
        ],
      });
      debugger
      setDataRemovalsPerDate({
        labels: data.removalsRates.map((element) => element.date),
        datasets: [
          {
            label: "Número de retiros por día",
            data: data.removalsRates.map((element) => element.cont),
            backgroundColor: "#33b5e5",
          },
        ],
      });
    }
  }, [data, uf]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card>
            <h4 className={classes.title} style={{ textAlign: "center" }}>
              Retiros por local
            </h4>
            <br></br>
            <CardContent>
              <Pie
                data={removalsPerLocal}
                height={300}
                options={{ responsive: true }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <h4 className={classes.title} style={{ textAlign: "center" }}>
              Pagos retiros por local
            </h4>
            <br></br>
            <CardContent>
              <Pie
                data={removalsPerLocalPayment}
                height={300}
                options={{ responsive: true }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <h4 className={classes.title} style={{ textAlign: "center" }}>
              Ganancias por local
            </h4>
            <br></br>
            <CardContent>
              <Pie
                data={profitPerLocal}
                height={300}
                options={{ responsive: true }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card>
            <h4 className={classes.title} style={{ textAlign: "center" }}>
              Densidad de retiros por día del mes
            </h4>
            <br></br>
            <CardContent>
              <Line
                data={dataRemovalsPerDate}
                height={200}
                options={{ responsive: true }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <h4 className={classes.title} style={{ textAlign: "center" }}>
              Utilidad por local
            </h4>
            <br></br>
            <CardContent>
              <Bar data={profitPerLocal} height={200} options={options} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <hr></hr>
      <Card>
        <h4 className={classes.title} style={{ textAlign: "center" }}>
          Kilos de material reciclados
        </h4>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CardContent>
              <Radar
                data={dataTotalMaterials}
                height={300}
                options={{ responsive: true }}
              />
            </CardContent>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Material</TableCell>
                  <TableCell align="right">Kilos reciclados</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {materials.map((material, i) => (
                  <TableRow key={i}>
                    <TableCell>{parseFlag(material._id)}</TableCell>
                    <TableCell align="right">{material.quantity} Kg</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <b>TOTAL:</b>{" "}
                  </TableCell>
                  <TableCell align="right">
                    <b>
                      {materials.length
                        ? materials.reduce(
                            (ac, material) => ac + material.quantity * 1,
                            0
                          )
                        : 0}{" "}
                      Kg
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
