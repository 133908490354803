import React, { Fragment, useEffect } from "react";
import { TextField, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from 'react-select';

const TextFieldComponent = ({ disabled, value, onChange, name, label }) => {
  return (
    <TextField
      id="outlined-required"
      variant="outlined"
      margin="dense"
      disabled={disabled}
      value={value}
      name={name}
      label={label}
      onChange={onChange}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  );
};

const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};


function Disp({ disabled, setFormData, list_materials, formData }) {
  const utils = useSelector((state) => state.utils);
  const updateDispositions  = index => (even, value) => { 
    let temp = {
      ...formData.dispositions,
      [index]: value != null ? value._id : null,
    };

    let aux = 0;
    Object.keys(temp).map((key) => {
      aux += temp[key] * 1;
    });

    setFormData({
      ...formData,
      dispositions: temp,
    });
    
    
  };

  const handleDisp = ( matr, event) => {
    let temp = {
      ...formData.dispositions,
      [matr]: event.value
    };
    setFormData({
      ...formData,
      dispositions: temp,
    });
  };


  return (
    <Fragment>
      {formData.materials &&
        
        <Grid item xs={12}>
        <hr></hr>
        
		{
		/*
		<Typography variant="h6">Paso 3: Reporte y voucher</Typography>
		*/
		}
       
        {Object.keys(list_materials).map((material) => (
          
          formData.materials[material.toUpperCase()]>0 ?(
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center"
            >
            
            <Grid item xs={12} sm={2}>
            <Typography variant="body2" align="center" color="textSecondary">{list_materials[material].name}:  </Typography>
            </Grid> 
			
            <Grid item xs={12} sm={5}>
				{ /*
					utils.dispositions.find(element => element._id ==formData.dispositions[material.toUpperCase()]).name!=null ?
					<Select
					  menuPlacement="top"
					  options={utils.dispositions.map(opt => ({ label: opt.name, value: opt._id }))}
					  onChange={(e) => handleDisp(material.toUpperCase(), e)}
						defaultValue={{ label: utils.dispositions.find(element => element._id ==formData.dispositions[material.toUpperCase()]).name, value: formData.dispositions[material.toUpperCase()] }}
					/>
					:
					<Select
					  menuPlacement="top"
					  options={utils.dispositions.map(opt => ({ label: opt.name, value: opt._id }))}
					  onChange={(e) => handleDisp(material.toUpperCase(), e)}
					/>
					
					anterior
					
					 <Select
                  menuPlacement="top"
                  options={utils.dispositions.map(opt => ({ label: opt.name, value: opt._id }))}
                  onChange={(e) => handleDisp(material.toUpperCase(), e)}
                  defaultValue={formData.dispositions!=null && formData.dispositions.length>0 ?{ label: utils.dispositions.find(element => element._id ==formData.dispositions[material.toUpperCase()]).name, value: formData.dispositions[material.toUpperCase()] }:null}
                />
					*/
				}
            
				<Select
                    menuPlacement="top"
                    options={utils.dispositions.map(opt => ({ label: opt.name, value: opt._id }))}
                    onChange={(e) => handleDisp(material.toUpperCase(), e)}
                    defaultValue={formData.dispositions!=null?(
                      formData.dispositions[material.toUpperCase()]!=null?{ label: utils.dispositions.find(element => element._id ==formData.dispositions[material.toUpperCase()]).name, value: formData.dispositions[material.toUpperCase()] }:null):null}
                  />
				
            </Grid> 
            </Grid> 
          ):""
          
        ))} 
        </Grid>
        }
      
    </Fragment>
  );
}

export default Disp;
