import React, { Fragment } from "react";
import { Button, ButtonGroup, Grid, TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

export const ButtonSwitch = ({
  view,
  setView,
  name,
  textPositive,
  textNegative,
}) => {
  return (
    <Fragment>
      <hr />
      <Button
        color="primary"
        onClick={() => setView({ ...view, [name]: !view[name] })}
      >
        {view[name] ? textNegative : textPositive}
      </Button>
    </Fragment>
  );
};

export const ButtonGroupAction = ({
  handleClose,
  handleSuccess,
  handleVali,
  view,
  formData,
}) => {
  return (
    <ButtonGroup size="small">
      <Button
        onClick={handleClose}
        color="secondary"
        variant="contained"
        startIcon={<ClearIcon />}
      >
        Cancelar
      </Button>
      <Button
        onClick={handleSuccess}
        color="primary"
        variant={formData.localID ? "contained" : "outlined"}
        startIcon={<CheckIcon />}
      >
        {view.buttonName}
      </Button>
      <Button
        onClick={handleVali}
        color="success"
        variant={formData.localID ? "contained" : "outlined"}
        startIcon={<CheckIcon />}
      >
        Validar
      </Button>
    </ButtonGroup>
  );
};

export const ButtonGroupAction2 = ({
  handleClose,
  handleSuccess,
  view,
  formData,
}) => {
  return (
    <ButtonGroup size="small">
      <Button
        onClick={handleClose}
        color="secondary"
        variant="contained"
        startIcon={<ClearIcon />}
      >
        Cancelar
      </Button>
      <Button
        onClick={handleSuccess}
        color="primary"
        variant={formData.localID ? "contained" : "outlined"}
        startIcon={<CheckIcon />}
      >
        {view.buttonName}
      </Button>
    </ButtonGroup>
  );
};

export const Notes = ({ formData, setFormData }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={formData.notes}
          rows={6}
          multiline
          label="Notas"
          onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
